export const mapTopics = (topic) => {
  switch (topic) {
    case "numeros":
      return "Números";
    case "algebra":
      return "Álgebra";
    case "geometria":
      return "Geometría";
    case "datos":
      return "Datos y azar";
    case "otros":
      return "Otros cursos";
    case "diplomado":
      return "Diplomado";
    default:
      return topic;
  }
};

export const mapPlatforms = (platform) => {
  switch (platform) {
    case "moodle":
      return "https://cmmedu.uchile.cl";
    case "u-cursos":
      return "https://cmmedu.uchile.cl";
    case "cmmeduformacion":
      return "https://cmmeduformacion.uchile.cl";
    case "redfid":
      return "https://cursos.redfid.cl";
    default:
      return "https://cmmedu.uchile.cl";
  }
}

export const mapCycles = (cycle) => {
  switch (cycle) {
    case "1":
      return "Primer ciclo";
    case "2":
      return "Segundo ciclo";
    case "12":
      return "Primer y segundo ciclo";
    case "3":
      return "Media";
    default:
      return cycle;
  }
}

export const mapContactMethods = (contact_method) => {
  switch (contact_method) {
    case "email":
      return "Correo";
    case "phone":
      return "Teléfono";
    case "whatsapp":
      return "WhatsApp";
    default:
      return contact_method;
  }
}

export const mapGenders = (gender) => {
  switch (gender) {
    case "male":
      return "Masculino";
    case "female":
      return "Femenino";
    case "other":
      return "Otro";
    default:
      return gender;
  }
}

export const mapTitles = (title) => {
  switch (title) {
    case "basica":
      return "Profesor(a) de Educación General Básica";
    case "matematicas":
      return "Profesor(a) de Matemáticas";
    case "no-title":
      return "Sin título";
    default:
      return title;
  }
}

export const mapEvents = (event) => {
  switch (event) {
    case "course_data_update":
      return { label: "Inició extracción de datos de un curso en edX", color: "#646464" };
    case "course_data_update_manual":
      return { label: "Solicitó actualizar datos de un curso", color: "#646464" };
    case "received_course_data":
      return { label: "Se recibieron datos de un curso desde edX", color: "#646464" };
    case "received_course_data_failed":
      return { label: "Falló en edX la extracción de datos de un curso", color: "red" };
    case "make_old_seguimiento":
      return { label: "Solicitó planillas antiguas de Seguimiento", color: "#646464" };
    case "processed_course_data":
      return { label: "Se cargaron datos nuevos en plataforma", color: "green" };
    case "processed_course_data_failed":
      return { label: "Falló la carga de datos nuevos en plataforma", color: "red" };
    case "made_old_seguimiento":
      return { label: "Se crearon planillas antiguas de Seguimiento", color: "green" };
    case "made_old_seguimiento_failed":
      return { label: "Falló la creación de planillas antiguas de Seguimiento", color: "red" };
    case "enable_seguimiento":
      return { label: "Habilitó seguimiento de una sección de un curso", color: "#646464" };
    case "disable_seguimiento":
      return { label: "Inhabilitó seguimiento de una sección de un curso", color: "#646464" };
    case "create_course":
      return { label: "Creó curso en catálogo", color: "#646464" };
    case "create_course_implementation":
      return { label: "Creó implementación de curso en catálogo", color: "#646464" };
    case "create_course_section":
      return { label: "Creó sección de curso en catálogo", color: "#646464" };
    case "update_course":
      return { label: "Actualizó curso en catálogo", color: "#646464" };
    case "update_course_implementation":
      return { label: "Actualizó implementación de curso en catálogo", color: "#646464" };
    case "update_course_section":
      return { label: "Actualizó sección de curso en catálogo", color: "#646464" };
    case "delete_course":
      return { label: "Eliminó curso en catálogo", color: "#646464" };
    case "delete_course_implementation":
      return { label: "Eliminó implementación de curso en catálogo", color: "#646464" };
    case "delete_course_section":
      return { label: "Eliminó sección de curso en catálogo", color: "#646464" };
    case "create_diplomado":
      return { label: "Creó diplomado en catálogo", color: "#646464" };
    case "update_diplomado":
      return { label: "Actualizó diplomado en catálogo", color: "#646464" };
    case "delete_diplomado":
      return { label: "Eliminó diplomado en catálogo", color: "#646464" };
    case "create_diplomado_module":
      return { label: "Creó módulo de diplomado en catálogo", color: "#646464" };
    case "update_diplomado_module":
      return { label: "Actualizó módulo de diplomado en catálogo", color: "#646464" };
    case "delete_diplomado_module":
      return { label: "Eliminó módulo de diplomado en catálogo", color: "#646464" };
    case "create_diplomado_implementation":
      return { label: "Creó implementación de diplomado en catálogo", color: "#646464" };
    case "update_diplomado_implementation":
      return { label: "Actualizó implementación de diplomado en catálogo", color: "#646464" };
    case "delete_diplomado_implementation":
      return { label: "Eliminó implementación de diplomado en catálogo", color: "#646464" };
    case "create_diplomado_section":
      return { label: "Creó sección de diplomado en catálogo", color: "#646464" };
    case "update_diplomado_section":
      return { label: "Actualizó sección de diplomado en catálogo", color: "#646464" };
    case "delete_diplomado_section":
      return { label: "Eliminó sección de diplomado en catálogo", color: "#646464" };
    case "delete_from_queue":
      return { label: "Canceló solicitud de actualización de datos de curso", color: "#646464" };
    case "update_user":
      return { label: "Editó perfil de profesor", color: "#646464" };
    case "delete_user":
      return { label: "Eliminó datos de profesor", color: "#646464" };
    default:
      return { label: event, color: "#646464" };
  }
};

export const mapDashboards = (dashboard) => {
  switch (dashboard) {
      case "inicio":
          return "Inicio";
      case "catalogo":
          return "Catálogo de cursos";
      case "trafico":
          return "Tráfico web";
      case "profesores":
          return "Profesores";
      case "history":
          return "Historial";
      default:
          return dashboard;
  }
};