import React, { useState, useEffect } from "react";
import { Typography, Grid, Card, CardContent, IconButton, Box, Link } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CatalogCourseSectionEditModal from '../modals/catalog/course/crud/CatalogCourseSectionEditModal';
import CatalogCourseSectionDeleteModal from '../modals/catalog/course/crud/CatalogCourseSectionDeleteModal';
import CatalogCourseSectionSeguimientoModal from '../modals/catalog/course/crud/CatalogCourseSectionSeguimientoModal';
import { mapPlatforms } from '../../../utils/mappers';


const CatalogCourseSection = ({
    keycloak,
    courseIndex,
    implementationIndex,
    sectionIndex,
    catalog,
    setCatalog,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {


    const [sectionData, setSectionData] = useState(null);
    const [otherSections, setOtherSections] = useState(null);

    const [showSeguimientoModal, setShowSeguimientoModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleOpenSeguimientoModal = () => {
        setShowSeguimientoModal(true);
    }

    const handleCloseSeguimientoModal = () => {
        setShowSeguimientoModal(false);
    }

    const handleOpenEditModal = () => {
        setShowEditModal(true);
    }

    const handleCloseEditModal = () => {
        setShowEditModal(false);
    }

    const handleOpenDeleteModal = () => {
        setShowDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    }

    useEffect(() => {
        setSectionData(catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex]);
        setOtherSections(catalog.courses[courseIndex].implementations[implementationIndex].sections.filter(s => s !== catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex]));
    }, [catalog]);


    return (
        sectionData &&
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#efefef' }}>
            <CardContent sx={{ flexGrow: 1 }}>
                <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography mb={"0px"} fontSize={"1.0em"} variant="title">Sección {sectionData.location}{sectionData.section}</Typography>
                    <Box display={"flex"}>
                        <IconButton sx={{ width: 20, height: 20, marginRight: "5px" }} onClick={() => handleOpenSeguimientoModal()}>
                            <VisibilityIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton sx={{ width: 20, height: 20, marginRight: "5px" }} onClick={() => handleOpenEditModal()}>
                            <EditIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton sx={{ width: 20, height: 20 }} onClick={() => handleOpenDeleteModal()}>
                            <DeleteForeverIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                    </Box>
                </Grid>
            </CardContent>
            <Grid container spacing={2} sx={{ padding: '0 20px 20px 20px', alignSelf: 'flex-end' }}>
                <Grid item xs={12}>
                    <Typography fontSize={"0.8em"} variant="p">
                        <b><u>Curso</u></b>: {sectionData.course_key ? <Link href={mapPlatforms(catalog.courses[courseIndex].implementations[implementationIndex].platform) + "/courses/" + sectionData.course_key} target="_blank">{sectionData.course_key}</Link> : "—"}
                    </Typography>
                    <Typography fontSize={"0.8em"} variant="p">
                        <b><u>Participantes</u></b>: {catalog.courses[courseIndex].implementations[implementationIndex].sections.length}
                    </Typography>
                    <Typography fontSize={"0.8em"} variant="p">
                        <b><u>Respuestas</u></b>: {catalog.courses[courseIndex].implementations[implementationIndex].sections.length}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography fontSize={"0.8em"} variant="p" textAlign={"center"} sx={{ color: sectionData.seguimiento_enabled ? "green" : "#646464" }}>
                        {sectionData.seguimiento_enabled ? <b>Seguimiento habilitado</b> : <b>Seguimiento inhabilitado</b>}
                    </Typography>
                </Grid>
            </Grid>
            <>
                <CatalogCourseSectionEditModal
                    keycloak={keycloak}
                    open={showEditModal}
                    handleClose={handleCloseEditModal}
                    courseIndex={courseIndex}
                    implementationIndex={implementationIndex}
                    sectionIndex={sectionIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    otherSections={otherSections}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
                <CatalogCourseSectionDeleteModal
                    keycloak={keycloak}
                    open={showDeleteModal}
                    handleClose={handleCloseDeleteModal}
                    courseIndex={courseIndex}
                    implementationIndex={implementationIndex}
                    sectionIndex={sectionIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
                <CatalogCourseSectionSeguimientoModal
                    keycloak={keycloak}
                    open={showSeguimientoModal}
                    handleClose={handleCloseSeguimientoModal}
                    courseIndex={courseIndex}
                    implementationIndex={implementationIndex}
                    sectionIndex={sectionIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
            </>
        </Card>
    );

}

export default CatalogCourseSection;