import React, { useState } from 'react';
import { Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { deleteUser } from '../../../requests/deleteUser';


const ProfesorDeleteModal = ({
    keycloak,
    open,
    handleClose,
    selectedUser,
    setUsers,
    setAlertError,
    setAlertSuccess,
    alerts,
    setAlerts
}) => {

    const [formLoading, setFormLoading] = useState(false);

    const handleDeleteProfesor = async () => {
        setFormLoading(true);
        let response = await deleteUser(keycloak.token, selectedUser.id)
        if (response.error !== "") {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al eliminar profesor(a)", "text": response.error } });
        } else {
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Profesor(a) eliminado/a!`, "text": `Los datos del/la profesor(a) ${selectedUser.full_name} han sido eliminados correctamente.` } });
        }
        handleClose();
        setUsers(response.users);
        setFormLoading(false);
    }

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                Eliminar datos de profesor
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="p">
                            ¿Está seguro que desea eliminar los datos de <b>{selectedUser?.full_name ? selectedUser.full_name : "este usuario"}</b>? Se eliminará su perfil,
                            junto con todos los datos asociados a sus respuestas en los cursos. Esta acción es irreversible.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Button startIcon={<DeleteForeverIcon />} variant='red' onClick={handleDeleteProfesor} disabled={formLoading}>
                            Eliminar
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ProfesorDeleteModal;