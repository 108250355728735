import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, TextField, Dialog, DialogContent, DialogTitle, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { putCourse } from '../../../../../../requests/catalog/putCourse';


const CatalogCourseEditModal = ({
    keycloak,
    open,
    handleClose,
    index,
    catalog,
    setCatalog,
    otherCourses,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [courseShortname, setCourseShortname] = useState("");
    const [courseName, setCourseName] = useState("");
    const [courseDescription, setCourseDescription] = useState("");
    const [courseBase, setCourseBase] = useState("");
    const [courseCycle, setCourseCycle] = useState("none");
    const [courseTopic, setCourseTopic] = useState("none");
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let errors = {};
        if (courseShortname === "") {
            errors.courseShortname = "Por favor ingrese una sigla.";
        } else if (courseShortname.length !== 3) {
            errors.courseShortname = "La sigla debe tener 3 caracteres.";
        } else if (courseShortname !== courseShortname.toUpperCase()) {
            errors.courseShortname = "La sigla debe ser en mayúsculas.";
        } else if (otherCourses.some(course => course.shortname === courseShortname)) {
            errors.courseShortname = "Ya existe un curso con esta sigla.";
        }
        if (courseName === "") {
            errors.courseName = "Por favor ingrese un nombre.";
        } else if (courseName.length < 5 || courseName.length > 100) {
            errors.courseName = "El nombre debe tener entre 5 y 100 caracteres.";
        } else if (otherCourses.some(course => course.name === courseName)) {
            errors.courseName = "Ya existe un curso con este nombre.";
        }
        if (courseDescription === "") {
            errors.courseDescription = "Por favor ingrese una descripción.";
        } else if (courseDescription.length < 10 || courseDescription.length > 2000) {
            errors.courseDescription = "La descripción debe tener entre 10 y 2000 caracteres.";
        }
        if (courseBase === "") {
            errors.courseBase = "Por favor ingrese una course key.";
        }
        if (courseCycle === "none") {
            errors.courseCycle = "Por favor seleccione un ciclo.";
        }
        if (courseTopic === "none") {
            errors.courseTopic = "Por favor seleccione un eje temático.";
        }
        return errors;
    }

    const handleEditCourse = async () => {
        setErrors({});
        let validateErrors = validateForm();
        if (Object.keys(validateErrors).length !== 0) {
            setErrors(validateErrors);
            return;
        }
        setFormLoading(true);
        let editData = {
            "id": catalog.courses[index]._id,
            "shortname": courseShortname,
            "name": courseName,
            "description": courseDescription,
            "base_key": courseBase,
            "cycle": courseCycle,
            "topic": courseTopic
        }
        let response = await putCourse(keycloak.token, editData);
        if (response.catalog == null) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al crear curso", "text": response.error } });
        } else {
            setCatalog(response.catalog);
            handleClose();
            setCourseShortname("");
            setCourseName("");
            setCourseDescription("");
            setCourseBase("");
            setCourseCycle("none");
            setCourseTopic("none");
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Curso editado!`, "text": `El curso ha sido editado.` } });
        }
        setFormLoading(false);
    }

    useEffect(() => {
        if (catalog.courses[index] === undefined) {
            handleClose();
            return;
        }
        setCourseShortname(catalog.courses[index].shortname);
        setCourseName(catalog.courses[index].name);
        setCourseDescription(catalog.courses[index].description);
        setCourseBase(catalog.courses[index].base_key);
        setCourseCycle(catalog.courses[index].cycle);
        setCourseTopic(catalog.courses[index].topic);
    }, [catalog]);

    return (
        catalog.courses[index] &&
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.2em" }}>
                Editar curso
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
            <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label="Sigla"
                            variant="outlined"
                            value={courseShortname}
                            onChange={(e) => {
                                setCourseShortname(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, courseShortname: "" }))
                            }}
                            error={!!errors.courseShortname}
                            helperText={errors.courseShortname}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField
                            fullWidth
                            label="Nombre"
                            variant="outlined"
                            value={courseName}
                            onChange={(e) => {
                                setCourseName(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, courseName: "" }))
                            }}
                            error={!!errors.courseName}
                            helperText={errors.courseName}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Descripción"
                            variant="outlined"
                            multiline
                            rows={6}
                            value={courseDescription}
                            onChange={(e) => {
                                setCourseDescription(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, courseDescription: "" }))
                            }}
                            error={!!errors.courseDescription}
                            helperText={errors.courseDescription}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Course key del curso base"
                            variant="outlined"
                            value={courseBase}
                            onChange={(e) => {
                                setCourseBase(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, courseBase: "" }))
                            }}
                            error={!!errors.courseBase}
                            helperText={errors.courseBase}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Ciclo"
                            variant="outlined"
                            value={courseCycle}
                            select
                            onChange={(e) => {
                                setCourseCycle(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, courseCycle: "" }))
                            }}
                            error={!!errors.courseCycle}
                            helperText={errors.courseCycle}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"1"}>Primer ciclo</MenuItem>
                            <MenuItem value={"2"}>Segundo ciclo</MenuItem>
                            <MenuItem value={"12"}>Primer y segundo ciclo</MenuItem>
                            <MenuItem value={"3"}>Media</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Eje temático"
                            variant="outlined"
                            select
                            value={courseTopic}
                            onChange={(e) => {
                                setCourseTopic(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, courseTopic: "" }))
                            }}
                            error={!!errors.courseTopic}
                            helperText={errors.courseTopic}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"numeros"}>Números</MenuItem>
                            <MenuItem value={"algebra"}>Álgebra</MenuItem>
                            <MenuItem value={"geometria"}>Geometría</MenuItem>
                            <MenuItem value={"datos"}>Datos y azar</MenuItem>
                            <MenuItem value={"otros"}>Otros cursos</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button startIcon={<AddCircleOutlineIcon />} variant='blue' onClick={handleEditCourse} disabled={formLoading}>
                            Editar curso
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CatalogCourseEditModal;