import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";


const Resumen = ({ keycloak, catalog }) => {


    return (
        <>
            <Typography fontSize={{xs: "1.2em", md: "1.6em"}} mb={"10px"} variant="title">CMMEdu Analytics</Typography>
            <Typography fontSize={{xs: "0.8em", md: "1.0em"}} variant="title">Plataforma de datos y seguimiento de cursos y plataformas</Typography>
            <Grid container spacing={2}>
            </Grid>
        </>
    )
}

export default Resumen;