import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, CircularProgress } from "@mui/material";
import ProfesoresTable from "../components/tables/ProfesoresTable";
import { getProfesores } from "../../requests/getProfesores";
import regions from "../../assets/json/regions.json";
import establecimientos from "../../assets/json/establecimientos.json";


const Profesores = ({
    keycloak,
    catalog,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess
}) => {

    const [usersData, setUsersData] = useState(null);
    const [loadingUsers, setLoadingUsers] = useState(false);


    const fetchUsers = async (token) => {
        setLoadingUsers(true);
        const response = await getProfesores(token);
        console.log(response)
        if (!response.profesores) {
            setLoadingUsers(false);
            setAlerts({ ...alerts, "error": { "title": "Error al obtener profesores", "text": response.error } });
            setAlertError(true);
            return;
        }
        setUsersData(response.profesores);
        setLoadingUsers(false);
    }

    useEffect(() => {
        fetchUsers(keycloak.token);
    }, []);

    return (
        <>
            <Typography fontSize={{xs: "1.2em", md: "1.6em"}} mb={"10px"} variant="title">Profesores</Typography>
            <Typography fontSize={{xs: "0.8em", md: "1.0em"}} variant="title">Listados de profesores y establecimientos que han participado de los cursos del CMMEdu</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {(usersData && !loadingUsers) ?
                        <ProfesoresTable
                            keycloak={keycloak}
                            catalog={catalog}
                            users={usersData}
                            setUsers={setUsersData}
                            regions={regions}
                            establecimientos={establecimientos}
                            alerts={alerts}
                            setAlerts={setAlerts}
                            setAlertError={setAlertError}
                            setAlertSuccess={setAlertSuccess}
                        />
                        :
                        <Box width={"100%"} textAlign={"center"} mt={"50px"}>
                            <CircularProgress sx={{ "color": "#d23831" }} />
                        </Box>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default Profesores;