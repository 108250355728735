import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import QueueDeleteModal from '../modals/QueueDeleteModal';


const QueueTable = ({
    keycloak,
    queue,
    setQueue,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 25, page: 0 });

    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);

    const columns = [
        {
            field: 'priority',
            disableColumnMenu: true,
            headerName: 'Prioridad',
            sortable: false,
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'course_key',
            disableColumnMenu: true,
            headerName: 'Curso',
            sortable: false,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'course_platform',
            disableColumnMenu: true,
            headerName: 'Plataforma',
            sortable: false,
            width: 140,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'initiated_by',
            disableColumnMenu: true,
            headerName: 'Autor',
            sortable: false,
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'detail',
            disableColumnMenu: true,
            headerName: 'Cancelar',
            sortable: false,
            width: 120,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <IconButton
                    onClick={() => { setSelectedTask(params.row); setDeleteModal(true) }}
                >
                    <DeleteForeverIcon />
                </IconButton>
            ),
        },
    ];

    function makeRows() {
        let rows = [];
        for (let [key, value] of Object.entries(queue)) {
            rows.push({
                id: key,
                priority: parseInt(key) + 1,
                course_key: value.course_key,
                course_platform: value.course_platform,
                course_type: value.course_type,
                initiated_by: value.initiated_by
            });
        }
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
            </GridToolbarContainer>
        );
    }

    return (
        Object.keys(queue).length === 0 ?
            <Box width={"100%"} textAlign={"center"} mt={"20px"}>
                <Typography variant='p' textAlign={"center"}>No hay actualizaciones de datos de cursos en cola.</Typography>
            </Box>
            :
            <div style={{ width: '100%' }}>
                <DataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[25]}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'priority',
                                    sort: 'asc',
                                },
                            ],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
                {selectedTask &&
                    <QueueDeleteModal
                        keycloak={keycloak}
                        open={deleteModal}
                        handleClose={() => setDeleteModal(false)}
                        selectedTask={selectedTask}
                        setQueue={setQueue}
                        setAlertSuccess={setAlertSuccess}
                        setAlertError={setAlertError}
                        alerts={alerts}
                        setAlerts={setAlerts}
                    />
                }
            </div>
    );
};

export default QueueTable;