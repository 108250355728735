import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogContent, DialogTitle, IconButton, Button, MenuItem, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { putCourseImplementation } from '../../../../../../requests/catalog/putCourseImplementation';


const CatalogCourseImplementationEditModal = ({
    keycloak,
    open,
    handleClose,
    courseIndex,
    implementationIndex,
    catalog,
    setCatalog,
    otherImplementations,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [implementationYear, setImplementationYear] = useState("");
    const [implementationTerm, setImplementationTerm] = useState("none");
    const [implementationType, setImplementationType] = useState("none");
    const [implementationPlatform, setImplementationPlatform] = useState("none");
    const [errors, setErrors] = useState({});
    const [formLoading, setFormLoading] = useState(false);

    const validateForm = () => {
        let tempErrors = {};
        if (implementationYear === "") {
            tempErrors.implementationYear = "Por favor ingrese un año.";
        } else if (isNaN(implementationYear)) {
            tempErrors.implementationYear = "Por favor ingrese un año válido.";
        } else if (implementationYear.toString().length !== 4) {
            tempErrors.implementationYear = "Por favor ingrese un año válido.";
        }
        if (implementationTerm === "none") {
            tempErrors.implementationTerm = "Por favor seleccione un semestre.";
        }
        if (implementationType === "none") {
            tempErrors.implementationType = "Por favor seleccione una modalidad.";
        }
        if (implementationPlatform === "none") {
            tempErrors.implementationPlatform = "Por favor seleccione una plataforma.";
        }
        if (otherImplementations.some(impl => impl.year === implementationYear && impl.term === implementationTerm && impl.type === implementationType)) {
            tempErrors.implementationYear = "Ya existe una implementación con estos datos.";
            tempErrors.implementationTerm = "Ya existe una implementación con estos datos.";
            tempErrors.implementationType = "Ya existe una implementación con estos datos.";
        }
        return tempErrors;
    }

    const handleEditImplementation = async () => {
        setErrors({});
        let validateErrors = validateForm();
        if (Object.keys(validateErrors).length !== 0) {
            setErrors(validateErrors);
            return;
        }
        setFormLoading(true);
        let editData = {
            "id": catalog.courses[courseIndex].implementations[implementationIndex]._id,
            "course_id": catalog.courses[courseIndex]._id,
            "year": implementationYear,
            "term": implementationTerm,
            "type": implementationType,
            "platform": implementationPlatform
        }
        let response = await putCourseImplementation(keycloak.token, editData);
        if (response.catalog == null) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al editar implementación", "text": response.error } });
        } else {
            setCatalog(response.catalog);
            handleClose();
            setImplementationYear("");
            setImplementationTerm("none");
            setImplementationType("none");
            setImplementationPlatform("none");
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Implementación editada!`, "text": `La implementación seleccionada del curso ${catalog.courses[courseIndex].shortname} se ha editado correctamente.` } });
        }
        setFormLoading(false);
    }

    useEffect(() => {
        if (catalog.courses[courseIndex].implementations[implementationIndex] === undefined) {
            handleClose();
            return;
        }
        setImplementationYear(catalog.courses[courseIndex].implementations[implementationIndex].year);
        setImplementationTerm(catalog.courses[courseIndex].implementations[implementationIndex].term);
        setImplementationType(catalog.courses[courseIndex].implementations[implementationIndex].type);
        setImplementationPlatform(catalog.courses[courseIndex].implementations[implementationIndex].platform);
    }, [catalog]);

    return (
        catalog.courses[courseIndex].implementations[implementationIndex] &&
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.2em" }}>
                Editar implementación
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Año"
                            variant="outlined"
                            value={implementationYear}
                            onChange={(e) => {
                                setImplementationYear(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, implementationYear: "" }))
                            }}
                            error={!!errors.implementationYear}
                            helperText={errors.implementationYear}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Semestre"
                            variant="outlined"
                            select
                            value={implementationTerm}
                            onChange={(e) => {
                                setImplementationTerm(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, implementationTerm: "" }))
                            }}
                            error={!!errors.implementationTerm}
                            helperText={errors.implementationTerm}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"0"}>Anual</MenuItem>
                            <MenuItem value={"1"}>Primer semestre</MenuItem>
                            <MenuItem value={"2"}>Segundo semestre</MenuItem>
                            <MenuItem value={"3"}>Verano</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Modalidad"
                            variant="outlined"
                            select
                            value={implementationType}
                            onChange={(e) => {
                                setImplementationType(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, implementationType: "" }))
                            }}
                            error={!!errors.implementationType}
                            helperText={errors.implementationType}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"b-learning"}>b-learning</MenuItem>
                            <MenuItem value={"e-learning"}>e-learning</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Plataforma"
                            variant="outlined"
                            select
                            value={implementationPlatform}
                            onChange={(e) => {
                                setImplementationPlatform(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, implementationPlatform: "" }))
                            }}
                            error={!!errors.implementationPlatform}
                            helperText={errors.implementationPlatform}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"moodle"}>Moodle</MenuItem>
                            <MenuItem value={"u-cursos"}>U-Cursos</MenuItem>
                            <MenuItem value={"cmmeduformacion"}>Open edX (CMMEdu Formación)</MenuItem>
                            <MenuItem value={"redfid"}>Open edX (RedFID)</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button startIcon={<EditIcon />} variant='blue' onClick={handleEditImplementation} disabled={formLoading}>
                            Editar implementación
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CatalogCourseImplementationEditModal;