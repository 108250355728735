import React, { useState, useEffect } from "react";
import { Typography, Grid, Button, Box, CircularProgress } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CourseSeguimientoModal from "../modals/CourseSeguimientoModal";
import { formatTimestamp } from "../../../utils/formatters";


const CourseSection = ({
    keycloak,
    course,
    selectedImplementation,
    selectedSection,
    sectionData,
    loadingSectionData,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [seguimientoModal, setSeguimientoModal] = useState(false);

    return (
        !loadingSectionData &&
        <>
            <Grid container spacing={2} mt={"5px"}>
                <Grid item xs={12} md={9}>
                    <Typography mb={{xs: 0, md: "20px"}} fontSize={{ xs: "0.8em", sm: "0.9em", md: "1.0em", lg: "1.2em" }} variant="title">Sección {course.implementations[selectedImplementation]?.sections[selectedSection]?.location}{course.implementations[selectedImplementation]?.sections[selectedSection]?.section} de la implementación {course.implementations[selectedImplementation]?.year}-{course.implementations[selectedImplementation]?.term} ({course.implementations[selectedImplementation]?.type}) de {course.shortname}</Typography>
                </Grid>
                <Grid item xs={12} md={3} display={"flex"} justifyContent={{ xs: "center", md: "end" }} mb={"10px"}>
                    <Button
                        variant="red"
                        startIcon={<VisibilityIcon />}
                        color="primary"
                        sx={{ fontSize: "0.6em", marginRight: {xs: 0, md: "2px"} }}
                        disabled={loadingSectionData}
                        onClick={() => {
                            setSeguimientoModal(true);
                        }}
                    >
                        Gestionar datos
                    </Button>
                </Grid>
            </Grid>
            {sectionData ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {sectionData.last_update ?
                            <Typography fontSize={"0.8em"} variant="p">Última actualización: {formatTimestamp(sectionData.last_update)}</Typography>
                            :
                            <Box width={"100%"} mt={"50px"}>
                                <Typography textAlign={"center"} variant="p-error">No se encontraron datos para esta sección.</Typography>
                            </Box>
                        }
                    </Grid>
                </Grid>
                :
                <Box width={"100%"} mt={"50px"}>
                    <Typography textAlign={"center"} variant="p-error">Error desconocido.</Typography>
                </Box>
            }
            <CourseSeguimientoModal
                keycloak={keycloak}
                open={seguimientoModal}
                handleClose={() => setSeguimientoModal(false)}
                course={course}
                selectedImplementation={selectedImplementation}
                selectedSection={selectedSection}
                setAlertSuccess={setAlertSuccess}
                setAlertError={setAlertError}
                alerts={alerts}
                setAlerts={setAlerts}
            />
        </>
    );
}

export default CourseSection;