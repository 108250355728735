import React, { useState, useEffect } from "react";
import { Typography, Grid, Card, CardContent, IconButton, Box, Chip } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CatalogDiplomadoModuleEditModal from '../modals/catalog/diplomado/crud/CatalogDiplomadoModuleEditModal';
import CatalogDiplomadoModuleDeleteModal from '../modals/catalog/diplomado/crud/CatalogDiplomadoModuleDeleteModal';
import CatalogDiplomadoImplementationsModal from '../modals/catalog/diplomado/CatalogDiplomadoImplementationsModal';


const CatalogDiplomadoModule = ({
    keycloak,
    diplomadoIndex,
    moduleIndex,
    catalog,
    setCatalog,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [moduleData, setModuleData] = useState(null);
    const [otherModules, setOtherModules] = useState(null);

    const [showImplementationsModal, setShowImplementationsModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleOpenImplementationsModal = () => {
        setShowImplementationsModal(true);
    }

    const handleCloseImplementationsModal = () => {
        setShowImplementationsModal(false);
    }

    const handleOpenEditModal = () => {
        setShowEditModal(true);
    }

    const handleCloseEditModal = () => {
        setShowEditModal(false);
    }

    const handleOpenDeleteModal = () => {
        setShowDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    }

    useEffect(() => {
        setModuleData(catalog.diplomados[diplomadoIndex].modules[moduleIndex]);
        setOtherModules(catalog.diplomados[diplomadoIndex].modules.filter(i => i !== catalog.diplomados[diplomadoIndex].modules[moduleIndex]));
    }, [catalog]);

    return (
        moduleData &&
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#efefef' }}>
            <CardContent sx={{ flexGrow: 1 }}>
                <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography mb={"0px"} fontSize={"1.0em"} variant="title">{moduleData.module + " — " + moduleData.name}</Typography>
                    <Box display={"flex"}>
                        <IconButton sx={{ width: 20, height: 20, marginRight: "5px" }} onClick={() => handleOpenImplementationsModal()}>
                            <ListIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton sx={{ width: 20, height: 20, marginRight: "5px" }} onClick={() => handleOpenEditModal()}>
                            <EditIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton sx={{ width: 20, height: 20 }} onClick={() => handleOpenDeleteModal()}>
                            <DeleteForeverIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                    </Box>
                </Grid>
            </CardContent>
            <Grid container spacing={2} sx={{ padding: '0 20px 20px 20px', alignSelf: 'flex-end' }}>
            </Grid>
            <>
                <CatalogDiplomadoImplementationsModal
                    keycloak={keycloak}
                    open={showImplementationsModal}
                    handleClose={handleCloseImplementationsModal}
                    diplomadoIndex={diplomadoIndex}
                    moduleIndex={moduleIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
                <CatalogDiplomadoModuleEditModal
                    keycloak={keycloak}
                    open={showEditModal}
                    handleClose={handleCloseEditModal}
                    diplomadoIndex={diplomadoIndex}
                    moduleIndex={moduleIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    otherModules={otherModules}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
                <CatalogDiplomadoModuleDeleteModal
                    keycloak={keycloak}
                    open={showDeleteModal}
                    handleClose={handleCloseDeleteModal}
                    diplomadoIndex={diplomadoIndex}
                    moduleIndex={moduleIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                /> 
            </>
        </Card>
    );

}

export default CatalogDiplomadoModule;