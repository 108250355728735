import React from 'react';
import { Box, Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Divider, Link, List } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { mapGenders, mapTitles, mapContactMethods } from '../../../utils/mappers';
import { capitalizeFirstLetter } from '../../../utils/formatters';


const ProfesorProfileModal = ({
    keycloak,
    open,
    handleClose,
    selectedUser,
    catalog,
    regions,
    establecimientos
}) => {

    const getEnrollmentsData = (enrollments) => {
        const groupedData = {};

        enrollments.forEach(({ course_key }) => {
            for (const course of catalog.courses) {
                const implementation = course.implementations.find(
                    (impl) => impl.sections.some(
                        (section) => section.course_key === course_key
                    )
                );

                if (implementation) {
                    const matchingSection = implementation.sections.find(
                        (section) => section.course_key === course_key
                    );

                    if (matchingSection) {
                        const yearTerm = `${implementation.year}-${implementation.term}`;

                        if (!groupedData[yearTerm]) {
                            groupedData[yearTerm] = [];
                        }

                        groupedData[yearTerm].push({
                            course_name: course.name,
                            course_shortname: course.shortname,
                            year: implementation.year,
                            term: implementation.term,
                            type: implementation.type,
                            location: matchingSection.location,
                            section: matchingSection.section,
                            course_key: matchingSection.course_key
                        });
                    }
                }
            }
        });

        return Object.entries(groupedData)
            .sort(([a], [b]) => b.localeCompare(a))
            .map(([yearTerm, enrollments]) => ({ yearTerm, enrollments }));
    };


    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                Perfil de {selectedUser?.full_name ? selectedUser.full_name : "usuario"}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="p" fontWeight={"bold"}>Información personal</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>RUT:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.run}</Typography>
                            </Grid>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>Nombre:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.first_name}</Typography>
                            </Grid>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>Apellido paterno:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.last_name_1}</Typography>
                            </Grid>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>Apellido materno:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.last_name_2}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>Método de contacto:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.contact_method ? mapContactMethods(selectedUser.contact_method) : "—"}</Typography>
                            </Grid>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>Correo:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.personal_email !== "—" ? <Link href={"mailto:" + selectedUser.personal_email}>{selectedUser.personal_email}</Link> : "—"}</Typography>
                            </Grid>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>Teléfono:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.phone}</Typography>
                            </Grid>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>Staff:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.is_staff ? "Sí" : "No"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>Primer acceso:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.date_joined}</Typography>
                            </Grid>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>Acceso más reciente:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.last_login}</Typography>
                            </Grid>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>Género:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.gender !== "—" ? mapGenders(selectedUser.gender) : "—"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>Título:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.title !== "—" ? mapTitles(selectedUser.title) : "—"}</Typography>
                            </Grid>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>Región:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.region}</Typography>
                            </Grid>
                            <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                <Typography mr={"10px"} variant="p-small"><b><u>Comuna:</u></b></Typography>
                                <Typography variant="p-small">{selectedUser?.comuna}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {(selectedUser?.schools && selectedUser.schools.length > 0) &&
                        <Grid item xs={12}>
                            <Typography variant="p" fontWeight={"bold"}>Establecimientos</Typography>
                            <Divider />
                        </Grid>
                    }
                    {selectedUser?.schools
                        ?.sort((a, b) => {
                            if (!a.date_end && b.date_end) return -1;
                            if (a.date_end && !b.date_end) return 1;
                            if (a.date_end && b.date_end) {
                                const diffEnd = new Date(a.date_end) - new Date(b.date_end);
                                if (diffEnd !== 0) return diffEnd;
                            }
                            const diffStart = new Date(a.date_start) - new Date(b.date_start);
                            if (diffStart !== 0) return diffStart;
                            return 0;
                        })
                        .map((school, index) => (
                            <Grid item xs={12} key={index}>
                                <Typography fontWeight={"bold"} variant='p'>
                                    [{school.rbd}] — {school.name}
                                </Typography>
                                <Typography variant='p-small'>
                                    {school.region} — {capitalizeFirstLetter(school.comuna.toLowerCase())}
                                </Typography>
                                <Typography variant='p-small'>
                                    {school.date_start.slice(0, 10)} — {school.date_end ? school.date_end.slice(0, 10) : "actual"}
                                </Typography>
                            </Grid>
                        ))}
                    {(selectedUser?.enrollments && selectedUser.enrollments.length > 0) &&
                        <Grid item xs={12}>
                            <Typography variant="p" fontWeight={"bold"}>Participación en cursos</Typography>
                            <Divider />
                        </Grid>
                    }
                    {selectedUser?.enrollments && getEnrollmentsData(selectedUser.enrollments).map(({ yearTerm, enrollments }) => (
                        <Grid item xs={12} key={yearTerm}>
                            <Typography fontWeight={"bold"} variant='p'>{yearTerm}</Typography>
                            {enrollments.map((enrollment, index) => (
                                <Grid ml={"20px"} container key={index}>
                                    <Grid item mt={"10px"} xs={12}>
                                        <Typography variant='p'>{enrollment.course_name} ({enrollment.course_shortname})</Typography>
                                    </Grid>
                                    <Grid item xs={12} display={"flex"} alignItems={"left"}>
                                        <Typography variant='p-small'>{enrollment.type}</Typography>
                                        <Typography m={"0px 10px"} variant='p-small'>{" — "}</Typography>
                                        <Typography variant='p-small'>{enrollment.course_key}</Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ProfesorProfileModal;