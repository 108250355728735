import React, { useState } from "react";
import { Typography, Grid, Button, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import { postUpdateCourseData } from "../../../requests/postUpdateCourseData";
import { postMakeOldSeguimiento } from "../../../requests/postMakeOldSeguimiento";


const CourseSeguimientoModal = ({
    keycloak,
    open,
    handleClose,
    course,
    selectedImplementation,
    selectedSection,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [formLoading, setFormLoading] = useState(false);

    const handleMakeUpdateCourseData = async () => {
        setFormLoading(true);
        let data = {
            "course_key": course.implementations[selectedImplementation]?.sections[selectedSection]?.course_key,
            "course_platform": course.implementations[selectedImplementation]?.platform,
            "course_type": "course",
            "token": keycloak.token
        }
        let response = await postUpdateCourseData(data);
        if (response.success) {
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": "Solicitud creada", "text": "Se ha agregado esta solicitud a la cola de tareas. En cuanto el servidor de Eol esté disponible, se ejecutará, y cuanto la tarea esté lista, los datos del curso se actualizarán." } });
        } else {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error en la solicitud", "text": response.error } });
        }
        setFormLoading(false);
    }

    const handleMakeOldSeguimiento = async () => {
        setFormLoading(true);
        let data = {
            "course_key": course.implementations[selectedImplementation]?.sections[selectedSection]?.course_key,
            "course_platform": course.implementations[selectedImplementation]?.platform,
            "course_type": "course"
        }
        let response = await postMakeOldSeguimiento(keycloak.token, data);
        if (response.success) {
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": "Solicitud creada", "text": "Se ha iniciado la generación de la planilla. En cuanto esté lista, se subirá automáticamente a Google Drive." } });
        } else {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error en la solicitud", "text": response.error } });
        }
        setFormLoading(false);
    }

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.2em" }}>
                Actualización de datos
                <Typography mt={"5px"} fontSize={"0.7em"}>{course.implementations[selectedImplementation]?.sections[selectedSection]?.course_key}</Typography>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Typography variant="p">
                    Aquí se puede forzar la actualización de datos de esta sección, independiente de si el seguimiento automático esté habilitado o no.
                    Esto debe realizarse con precaución y sólo en casos excepcionales, ya que puede generar un consumo no contemplado de recursos de Eol.
                </Typography>
                <Typography mt={"20px"} variant="p">
                    También se puede generar el reporte de seguimiento en el formato antiguo (planilla) y subirlo a Drive.
                </Typography>
                <Grid container spacing={2} mt={"20px"}>
                    <Grid item xs={12} sm={6} display={"flex"} justifyContent={"center"}>
                        <Button
                            sx={{ "marginRight": "5px", "fontSize": "0.8em" }}
                            variant={"red"}
                            disabled={formLoading}
                            startIcon={<DownloadIcon />}
                            onClick={handleMakeUpdateCourseData}
                        >
                            Actualizar datos
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} display={"flex"} justifyContent={"center"}>
                        <Button
                            sx={{ "fontSize": "0.8em" }}
                            variant={"red"}
                            disabled={formLoading}
                            startIcon={<DescriptionIcon />}
                            onClick={handleMakeOldSeguimiento}
                        >
                            Generar planilla
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CourseSeguimientoModal;