import React from 'react';
import { Box, Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Divider, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QueueTable from '../tables/QueueTable';


const QueueModal = ({
    keycloak,
    open,
    handleClose,
    queue,
    setQueue,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                Cola de actualizaciones
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <QueueTable
                    keycloak={keycloak}
                    queue={queue}
                    setQueue={setQueue}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
            </DialogContent>
        </Dialog>
    );
}

export default QueueModal;