import React from "react";
import { Typography, Grid, Button } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';


const CourseImplementationsAll = ({
    keycloak,
    loadingSectionData,
    setLoadingSectionData,
    course
}) => {


    return (
        <Grid container spacing={2} mt={"5px"}>
            <Grid item xs={12}>
                <Typography fontSize={{xs: "0.8em", sm: "0.9em", md: "1.0em", lg: "1.2em"}} variant="title">Resumen general del curso {course.shortname}</Typography>
            </Grid>
        </Grid>
    );
}

export default CourseImplementationsAll;