import React, { useState } from "react";
import { Typography, Grid, Button, Box, CircularProgress } from "@mui/material";
import CatalogCourse from "../components/catalog/CatalogCourse";
import CatalogDiplomado from "../components/catalog/CatalogDiplomado";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CatalogCourseCreateModal from "../components/modals/catalog/course/crud/CatalogCourseCreateModal";
import CatalogDiplomadoCreateModal from "../components/modals/catalog/diplomado/crud/CatalogDiplomadoCreateModal";


const Catalogo = ({
    keycloak,
    catalog,
    setCatalog,
    loadingCatalog,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [showCreateCourseModal, setShowCreateCourseModal] = useState(false);
    const [showCreateDiplomadoModal, setShowCreateDiplomadoModal] = useState(false);

    const handleOpenCreateCourseModal = () => {
        setShowCreateCourseModal(true);
    }

    const handleCloseCreateCourseModal = () => {
        setShowCreateCourseModal(false);
    }

    const handleOpenCreateDiplomadoModal = () => {
        setShowCreateDiplomadoModal(true);
    }

    const handleCloseCreateDiplomadoModal = () => {
        setShowCreateDiplomadoModal(false);
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Typography fontSize={{xs: "1.2em", md: "1.6em"}} mb={"10px"} variant="title">Catálogo de cursos</Typography>
                    <Typography fontSize={{xs: "0.8em", md: "1.0em"}} variant="title">Listado de cursos y sus implementaciones</Typography>
                </Grid>
            </Grid>
            {(catalog && !loadingCatalog) ?
                <Grid container spacing={2}>
                    <Grid item xs={12} mb={"20px"} textAlign="center">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} textAlign={{ xs: "center", sm: "right" }}>
                                <Button sx={{ mr: "5px" }} variant='blue' startIcon={<AddCircleOutlineIcon />} onClick={() => handleOpenCreateCourseModal()}>
                                    Agregar curso
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} textAlign={{ xs: "center", sm: "left" }}>
                                <Button variant='blue' startIcon={<AddCircleOutlineIcon />} onClick={() => handleOpenCreateDiplomadoModal()}>
                                    Agregar diplomado
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {Object.keys(catalog.courses).map((key) => {
                        return (
                            <Grid item xs={12} md={6} lg={4} key={key}>
                                <CatalogCourse
                                    keycloak={keycloak}
                                    courseIndex={key}
                                    catalog={catalog}
                                    setCatalog={setCatalog}
                                    setAlertSuccess={setAlertSuccess}
                                    setAlertError={setAlertError}
                                    alerts={alerts}
                                    setAlerts={setAlerts}
                                />
                            </Grid>
                        );
                    })}
                    {Object.keys(catalog.diplomados).map((key) => {
                        return (
                            <Grid item xs={12} md={6} lg={4} key={key}>
                                <CatalogDiplomado
                                    keycloak={keycloak}
                                    diplomadoIndex={key}
                                    catalog={catalog}
                                    setCatalog={setCatalog}
                                    setAlertSuccess={setAlertSuccess}
                                    setAlertError={setAlertError}
                                    alerts={alerts}
                                    setAlerts={setAlerts}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                : loadingCatalog ?
                    <Box width={"100%"} textAlign={"center"} mt={"50px"}>
                        <CircularProgress sx={{ "color": "#d23831" }} />
                    </Box>
                    : <></>
            }
            <CatalogCourseCreateModal
                keycloak={keycloak}
                open={showCreateCourseModal}
                handleClose={handleCloseCreateCourseModal}
                catalog={catalog}
                setCatalog={setCatalog}
                setAlertSuccess={setAlertSuccess}
                setAlertError={setAlertError}
                alerts={alerts}
                setAlerts={setAlerts}
            />
            <CatalogDiplomadoCreateModal
                keycloak={keycloak}
                open={showCreateDiplomadoModal}
                handleClose={handleCloseCreateDiplomadoModal}
                catalog={catalog}
                setCatalog={setCatalog}
                setAlertSuccess={setAlertSuccess}
                setAlertError={setAlertError}
                alerts={alerts}
                setAlerts={setAlerts}
            />
        </>
    )
}

export default Catalogo;