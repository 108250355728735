import axios from 'axios';

export const postMakeOldSeguimiento = async (token, data) => {

    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + "/make_old_seguimiento", {}, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
            params: {
                course_key: data.course_key,
                course_platform: data.course_platform,
                course_type: data.course_type
            }
        });
        switch (response.data.status) {
            case 100:
                return { success: true, error: "" };
            case 101:
                return { success: false, error: response.data.message };
            default:
                return { success: false, error: "Error desconocido: " + response.data.message };
        }
    } catch (error) {
        return { success: false, error: "Error desconocido: " + error.message };
    }
}