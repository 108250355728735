import axios from 'axios';

export const deleteDiplomado = async (token, diplomadoId) => {

    try {
        const response = await axios.delete(process.env.REACT_APP_API_URL + "/diplomado/" + diplomadoId, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
        return { catalog: response.data, error: "" };
    } catch (error) {
        switch (error.response.status) {
            case 404:
                return { catalog: null, error: "No se encontró el diplomado." };
            default:
                return { catalog: null, error: "Error desconocido: " + error.message };
        }
    }
}