import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, CircularProgress, TextField, MenuItem, Link } from '@mui/material';
import PieChart from '../components/charts/PieChart';
import BarChart from '../components/charts/BarChart';
import LineChart from '../components/charts/LineChart';
import { getAnalytics } from '../../requests/getAnalytics';


const WEBSITES = {
    "cmmedu": {
        "id": "2",
        "name": "CMMEdu",
        "url": "https://cmmedu.uchile.cl"
    },
    "redfid": {
        "id": "3",
        "name": "RedFID",
        "url": "https://www.redfid.cl"
    },
    "matcon": {
        "id": "4",
        "name": "MatCon",
        "url": "https://matcon.cmmedu.uchile.cl"
    },
    "suma-y-sigue": {
        "id": "6",
        "name": "Suma y Sigue",
        "url": "https://sumaysigue.uchile.cl"
    },
    "matematica-en-aula": {
        "id": "7",
        "name": "Matemática en Aula",
        "url": "https://matematicaenaula.cmmedu.uchile.cl"
    },
    "refip": {
        "id": "8",
        "name": "ReFIP",
        "url": "https://refip.cmmedu.uchile.cl"
    },
    "rutas-digitales": {
        "id": "9",
        "name": "Rutas Digitales",
        "url": "https://recursosplandiferenciado.cmmedu.uchile.cl"
    }
}

const CMMEDU_COLORS = [
    "#D23831",
    "#5BC0EB",
    "#FDE74C",
    "#9BC53D",
    "#388659",
    "#4E4187",
    "#595959"
]

const REDFID_COLORS = [
    "#40b4ba",
    "#eb947e",
    "#285943",
    "gold",
    "#DB5ABA",
    "#4E4187",
    "#595959"
];

const TraficoWeb = ({ keycloak }) => {

    const [website, setWebsite] = useState("cmmedu");
    const [data, setData] = useState(null);
    const [loadingAnalytics, setLoadingAnalytics] = useState(false);

    const fetchAnalytics = async () => {
        try {
            setLoadingAnalytics(true);
            const response = await getAnalytics(keycloak.token, WEBSITES[website].id);
            setData(response.analytics);
            setLoadingAnalytics(false);
        } catch (error) {
            setLoadingAnalytics(false);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchAnalytics();
    }, [website]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} width={"100%"}>
                    <Typography fontSize={{xs: "1.2em", md: "1.6em"}} mb={"10px"} variant="title">Tráfico web</Typography>
                    <Typography fontSize={{xs: "0.8em", md: "1.0em"}} variant="title">Visitas a las plataformas del CMMEdu</Typography>
                    <TextField
                        label="Sitio web"
                        value={website}
                        fullWidth
                        select
                        onChange={(e) => setWebsite(e.target.value)}
                    >
                        {Object.keys(WEBSITES).map((key) => (
                            <MenuItem key={key} value={key}>
                                {WEBSITES[key].name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {(!loadingAnalytics && data) &&
                    <Grid item xs={12} sm={6} md={8} width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                        <Typography fontSize={"1.0em"} variant="subtitle" textAlign={"center"}>URL: <Link target="_blank" href={WEBSITES[website].url} color="#D23831">{WEBSITES[website].url}</Link></Typography>
                        <Typography mb={0} fontSize={"1.0em"} variant="subtitle" textAlign={"center"}>Total visitantes: {Object.values(data.analytics.users_per_day).reduce((acc, item) => acc + item, 0)}</Typography>
                        <Typography fontSize={"1.0em"} variant="subtitle" textAlign={"center"}>Páginas visitadas: {Object.values(data.analytics.users_per_hostname).reduce((acc, item) => acc + item, 0)}</Typography>
                        <Typography fontSize={"0.7em"} textAlign={"center"} variant="p">La información mostrada corresponde a los últimos 30 días.</Typography>
                    </Grid>
                }
            </Grid>
            {loadingAnalytics ? <Box textAlign={"center"} mt={"50px"}><CircularProgress /></Box> :
                <Grid container spacing={2}>
                    {data ?
                        <Grid item xs={12} mt={"40px"}>
                            <Grid container mt={"5px"}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle" textAlign={"center"} fontSize={"1.2em"}>Usuarios por día</Typography>
                                    <LineChart
                                        id="users-by-day"
                                        data={Object.keys(data.analytics.users_per_day).map(key => ({ date: key, value: data.analytics.users_per_day[key] }))}
                                        timeInterval={"day"}
                                        lineColor={website === "redfid" ? REDFID_COLORS[0] : CMMEDU_COLORS[0]}
                                        tooltipFormatter={(value) => value + " visitas"}
                                        limit={7}
                                        horizontal={false}
                                        height={300}
                                        labelAngle={0}
                                    ></LineChart>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle" textAlign={"center"} fontSize={"1.2em"}>Visitas por sitio</Typography>
                                    <PieChart
                                        id={"visits-by-site"}
                                        height={"300px"}
                                        data={() => {
                                            let out = [];
                                            let tempOut = [];
                                            let otherValue = 0;
                                            const COLORS = website === "redfid" ? REDFID_COLORS : CMMEDU_COLORS;
                                            const getMainPath = (url, key) => {
                                                url = url.split(/[?#]/)[0];
                                                let route = "/" + url.split("/")[1];
                                                if (website === "redfid") {
                                                    if (route === "/") {
                                                        if (key.includes("https://www.redfid.cl")) {
                                                            return "Home principal";
                                                        } else if (key.includes("https://cursos.redfid.cl")) {
                                                            return "Home de Cursos";
                                                        } else if (key.includes("https://comunidades.redfid.cl")) {
                                                            return "Home de Comunidades";
                                                        } else if (key.includes("https://recursos.redfid.cl")) {
                                                            return "Home de Recursos";
                                                        }
                                                    } else if (route === "/events") {
                                                        return "Noticias";
                                                    } else if (route === "/courses") {
                                                        return "Contenido de cursos";
                                                    } else if (route === "/formulario-de-registro") {
                                                        return "Formulario de registro";
                                                    } else if (route === "/t") {
                                                        return "Discusiones de Comunidades";
                                                    } else if (route === "/dashboard") {
                                                        if (url.includes("display=1")) {
                                                            return "Talleres";
                                                        } else if (url.includes("display=2")) {
                                                            return "Webinars";
                                                        } else {
                                                            return "Home de Cursos";
                                                        }
                                                    } else if (route === "/c") {
                                                        return "Categorías de Comunidades";
                                                    } else if (route === "/academico") {
                                                        return "Recursos de investigación";
                                                    } else if (route === "/docente") {
                                                        return "Material docente";
                                                    }
                                                }
                                                return "/" + url.split("/")[1];
                                            };
                                            const ignoredExtensions = [".jpg", ".jpeg", ".gif", ".svg", ".ico"];
                                            const shouldIgnore = (url) => {
                                                return ignoredExtensions.some(ext => url.endsWith(ext));
                                            };
                                            for (let key of Object.keys(data.analytics.users_per_hostname)) {
                                                let strippedUrl = (website === "redfid" ? key.split("redfid.cl")[1] : key.split(WEBSITES[website].url)[1]) || "";
                                                if (shouldIgnore(strippedUrl)) {
                                                    continue;
                                                }
                                                let mainPath = getMainPath(strippedUrl, key);
                                                if (key.includes(website === "redfid" ? "redfid.cl" : WEBSITES[website].url)) {
                                                    let existingEntry = tempOut.find(item => item.label === mainPath);
                                                    if (existingEntry) {
                                                        existingEntry.value += data.analytics.users_per_hostname[key];
                                                    } else {
                                                        tempOut.push({
                                                            label: mainPath,
                                                            value: data.analytics.users_per_hostname[key],
                                                        });
                                                    }
                                                }
                                            }
                                            tempOut = tempOut.sort((a, b) => b.value - a.value);
                                            for (let i = 0; i < tempOut.length; i++) {
                                                if (i < 6) {
                                                    tempOut[i].color = COLORS[i % COLORS.length];
                                                    out.push(tempOut[i]);
                                                } else {
                                                    otherValue += tempOut[i].value;
                                                }
                                            }
                                            if (otherValue > 0) {
                                                out.push({ label: "Otros", value: otherValue, color: COLORS[COLORS.length - 1] });
                                            }
                                            out = out.sort((a, b) => b.value - a.value);
                                            return out;
                                        }}
                                    />
                                </Grid>
                                {website === "redfid" &&
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="subtitle" textAlign={"center"} fontSize={"1.2em"}>Visitas por ambiente</Typography>
                                        <PieChart
                                            id={"visits-by-platform"}
                                            height={"300px"}
                                            data={() => {
                                                let out = [];
                                                let addedOther = false;

                                                const updateOrAdd = (label, value, color) => {
                                                    let existingEntry = out.find(item => item.label === label);
                                                    if (existingEntry) {
                                                        existingEntry.value += value; // Sum the value if the entry exists
                                                    } else {
                                                        out.push({ label, value, color }); // Otherwise, add a new entry
                                                    }
                                                };

                                                for (let key of Object.keys(data.analytics.users_per_hostname)) {
                                                    if (key.includes("https://www.redfid.cl")) {
                                                        updateOrAdd("Home", data.analytics.users_per_hostname[key], REDFID_COLORS[0]);
                                                    } else if (key.includes("https://cursos.redfid.cl")) {
                                                        updateOrAdd("Aprendizaje Profesional", data.analytics.users_per_hostname[key], REDFID_COLORS[1]);
                                                    } else if (key.includes("https://comunidades.redfid.cl")) {
                                                        updateOrAdd("Comunidades", data.analytics.users_per_hostname[key], REDFID_COLORS[2]);
                                                    } else if (key.includes("https://recursos.redfid.cl")) {
                                                        updateOrAdd("Recursos", data.analytics.users_per_hostname[key], REDFID_COLORS[3]);
                                                    } else {
                                                        if (!addedOther) {
                                                            out.push({ label: "Otro", value: data.analytics.users_per_hostname[key], color: REDFID_COLORS[6] });
                                                            addedOther = true;
                                                        } else {
                                                            let otherEntry = out.find(item => item.label === "Otro");
                                                            otherEntry.value += data.analytics.users_per_hostname[key];
                                                        }
                                                    }
                                                }

                                                return out;
                                            }}

                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle" textAlign={"center"} fontSize={"1.2em"}>Usuarios por país</Typography>
                                    <BarChart
                                        id={"users-by-country"}
                                        sort={true}
                                        height={"300px"}
                                        horizontal={true}
                                        limit={10}
                                        data={() => {
                                            let out = [];
                                            for (let key of Object.keys(data.analytics.users_per_country)) {
                                                out.push({ label: key, value: data.analytics.users_per_country[key], color: website === "redfid" ? REDFID_COLORS[0] : CMMEDU_COLORS[0] });
                                            }
                                            return out;
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle" textAlign={"center"} fontSize={"1.2em"}>Usuarios por ciudad</Typography>
                                    <BarChart
                                        id={"users-by-city"}
                                        sort={true}
                                        height={"300px"}
                                        horizontal={true}
                                        limit={10}
                                        data={() => {
                                            let out = [];
                                            for (let key of Object.keys(data.analytics.users_per_city)) {
                                                if (key !== "(not set)") {
                                                    out.push({ label: key, value: data.analytics.users_per_city[key], color: website === "redfid" ? REDFID_COLORS[0] : CMMEDU_COLORS[0] });
                                                }
                                            }
                                            return out;
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle" textAlign={"center"} fontSize={"1.2em"}>Usuarios por tipo de dispositivo</Typography>
                                    <PieChart
                                        id={"users-by-device-type"}
                                        height={"300px"}
                                        data={() => {
                                            let out = [];
                                            var addedOther = false;
                                            for (let key of Object.keys(data.analytics.users_per_device_type)) {
                                                switch (key) {
                                                    case "Desktop":
                                                        out.push({ label: "Escritorio", value: data.analytics.users_per_device_type[key], color: website === "redfid" ? REDFID_COLORS[0] : CMMEDU_COLORS[0] });
                                                        break;
                                                    case "Smartphone":
                                                        out.push({ label: "Mobile", value: data.analytics.users_per_device_type[key], color: website === "redfid" ? REDFID_COLORS[1] : CMMEDU_COLORS[1] });
                                                        break;
                                                    case "Phablet":
                                                        out.push({ label: "Tablet", value: data.analytics.users_per_device_type[key], color: website === "redfid" ? REDFID_COLORS[2] : CMMEDU_COLORS[2] });
                                                        break;
                                                    default:
                                                        if (!addedOther) {
                                                            out.push({ label: "Otro", value: data.analytics.users_per_device_type[key], color: website === "redfid" ? REDFID_COLORS[6] : CMMEDU_COLORS[6] });
                                                            addedOther = true;
                                                        } else {
                                                            for (let item of out) {
                                                                if (item.label === "Otro") {
                                                                    item.value += data.analytics.users_per_device_type[key];
                                                                }
                                                            }
                                                        }
                                                        break;
                                                }
                                            }
                                            return out;
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle" textAlign={"center"} fontSize={"1.2em"}>Usuarios por navegador web</Typography>
                                    <BarChart
                                        id={"users-by-browser"}
                                        sort={true}
                                        height={"300px"}
                                        horizontal={true}
                                        limit={10}
                                        data={() => {
                                            let out = [];
                                            for (let key of Object.keys(data.analytics.users_per_browser)) {
                                                out.push({ label: key, value: data.analytics.users_per_browser[key], color: website === "redfid" ? REDFID_COLORS[0] : CMMEDU_COLORS[0] });
                                            }
                                            return out;
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle" textAlign={"center"} fontSize={"1.2em"}>Usuarios por sistema operativo</Typography>
                                    <BarChart
                                        id={"users-by-os"}
                                        sort={true}
                                        height={"300px"}
                                        horizontal={true}
                                        limit={10}
                                        data={() => {
                                            let out = [];
                                            for (let key of Object.keys(data.analytics.users_per_operating_system)) {
                                                out.push({ label: key, value: data.analytics.users_per_operating_system[key], color: website === "redfid" ? REDFID_COLORS[0] : CMMEDU_COLORS[0] });
                                            }
                                            return out;
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle" textAlign={"center"} fontSize={"1.2em"}>Origen de los usuarios</Typography>
                                    <BarChart
                                        id={"users-by-referrer"}
                                        sort={true}
                                        height={"300px"}
                                        horizontal={true}
                                        limit={10}
                                        data={() => {
                                            let out = [];
                                            for (let key of Object.keys(data.analytics.users_per_referrer)) {
                                                out.push({ label: key, value: data.analytics.users_per_referrer[key], color: website === "redfid" ? REDFID_COLORS[0] : CMMEDU_COLORS[0] });
                                            }
                                            return out;
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography variant="subtitle" textAlign={"center"} fontSize={"1.2em"}>Usuarios por hora</Typography>
                                    <BarChart
                                        id={"users-by-hour"}
                                        height={"300px"}
                                        horizontal={false}
                                        data={() => {
                                            let out = [];
                                            for (let i = 0; i < 24; i++) {
                                                let hour = i.toString();
                                                let value = data.analytics.users_per_hour[hour] !== undefined ? data.analytics.users_per_hour[hour] : 0;
                                                out.push({ label: hour, value: value, color: website === "redfid" ? REDFID_COLORS[0] : CMMEDU_COLORS[0] });
                                            }
                                            return out;
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Box variant={"p-error"} textAlign={"center"} mt={"40px"}>Ha ocurrido un error.</Box>
                        </Grid>
                    }
                </Grid >
            }
        </>
    );
}

export default TraficoWeb;