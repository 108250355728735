import React, { useState, useEffect } from "react";
import { Typography, Grid, Card, CardContent, IconButton, Box, Chip } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CatalogCourseImplementationEditModal from '../modals/catalog/course/crud/CatalogCourseImplementationEditModal';
import CatalogCourseImplementationDeleteModal from '../modals/catalog/course/crud/CatalogCourseImplementationDeleteModal';
import CatalogCourseSectionsModal from '../modals/catalog/course/CatalogCourseSectionsModal';


const CatalogCourseImplementation = ({
    keycloak,
    courseIndex,
    implementationIndex,
    catalog,
    setCatalog,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [implementationData, setImplementationData] = useState(null);
    const [otherImplementations, setOtherImplementations] = useState(null);

    const [showSectionsModal, setShowSectionsModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleOpenSectionsModal = () => {
        setShowSectionsModal(true);
    }

    const handleCloseSectionsModal = () => {
        setShowSectionsModal(false);
    }

    const handleOpenEditModal = () => {
        setShowEditModal(true);
    }

    const handleCloseEditModal = () => {
        setShowEditModal(false);
    }

    const handleOpenDeleteModal = () => {
        setShowDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    }

    useEffect(() => {
        setImplementationData(catalog.courses[courseIndex].implementations[implementationIndex]);
        setOtherImplementations(catalog.courses[courseIndex].implementations.filter(i => i !== catalog.courses[courseIndex].implementations[implementationIndex]));
    }, [catalog]);

    return (
        implementationData &&
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#efefef' }}>
            <CardContent sx={{ flexGrow: 1 }}>
                <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography mb={"0px"} fontSize={"1.0em"} variant="title">{implementationData.year + "-" + implementationData.term}</Typography>
                    <Box display={"flex"}>
                        <IconButton sx={{ width: 20, height: 20, marginRight: "5px" }} onClick={() => handleOpenSectionsModal()}>
                            <ListIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton sx={{ width: 20, height: 20, marginRight: "5px" }} onClick={() => handleOpenEditModal()}>
                            <EditIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton sx={{ width: 20, height: 20 }} onClick={() => handleOpenDeleteModal()}>
                            <DeleteForeverIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                    </Box>
                </Grid>
                <Box>
                    <Chip label={implementationData.type} variant={implementationData.type} />
                </Box>
            </CardContent>
            <Grid container spacing={2} sx={{ padding: '0 20px 20px 20px', alignSelf: 'flex-end' }}>
                <Grid item xs={12} md={6}>
                    <Typography fontSize={"0.8em"} variant="p">
                        <b><u>Plataforma</u></b>: {implementationData.platform === "u-cursos" ? "U-Cursos" : implementationData.platform === "moodle" ? "Moodle" : implementationData.platform === "cmmeduformacion" ? "Open edX (CMMEdu Formación" : implementationData.platform === "redfid" ? "Open edX (RedFID)" : "Otro"}
                    </Typography>
                    <Typography fontSize={"0.8em"} variant="p">
                        <b><u>Secciones</u></b>: {implementationData.sections.length}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography fontSize={"0.8em"} variant="p">
                        <b><u>Participantes</u></b>: {implementationData.sections.length}
                    </Typography>
                    <Typography fontSize={"0.8em"} variant="p">
                        <b><u>Respuestas</u></b>: {implementationData.sections.length}
                    </Typography>
                </Grid>
            </Grid>
            <>
                <CatalogCourseSectionsModal
                    keycloak={keycloak}
                    open={showSectionsModal}
                    handleClose={handleCloseSectionsModal}
                    courseIndex={courseIndex}
                    implementationIndex={implementationIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
                <CatalogCourseImplementationEditModal
                    keycloak={keycloak}
                    open={showEditModal}
                    handleClose={handleCloseEditModal}
                    courseIndex={courseIndex}
                    implementationIndex={implementationIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    otherImplementations={otherImplementations}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
                <CatalogCourseImplementationDeleteModal
                    keycloak={keycloak}
                    open={showDeleteModal}
                    handleClose={handleCloseDeleteModal}
                    courseIndex={courseIndex}
                    implementationIndex={implementationIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
            </>
        </Card>
    );

}

export default CatalogCourseImplementation;