import axios from 'axios';

export const getQueue = async (token) => {

    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + "/queue", {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });

        return { queue: response.data.queue, error: "" };

    } catch (error) {
        return { queue: null, error: "Error desconocido: " + error.message };
    }
}