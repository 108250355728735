import React, { useState, useEffect } from "react";
import { Typography, Grid, Paper, IconButton, TextField, CircularProgress, Box, Button, MenuItem, Divider } from "@mui/material";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import "dayjs/locale/es";
import QueueModal from "../components/modals/QueueModal";
import { getEvents } from "../../requests/getEvents";
import { getQueue } from "../../requests/getQueue";
import { mapEvents } from "../../utils/mappers";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import SearchIcon from '@mui/icons-material/Search';


const Historial = ({
    keycloak,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [data, setData] = useState(null);
    const [queue, setQueue] = useState(null);
    const [queueModal, setQueueModal] = useState(false);
    const [selectors, setSelectors] = useState(null);
    const [page, setPage] = useState(0);
    const [existsPreviousPage, setExistsPreviousPage] = useState(false);
    const [existsNextPage, setExistsNextPage] = useState(false);
    const [prevFilters, setPrevFilters] = useState({
        startTimestamp: null,
        endTimestamp: null,
        username: "all",
        eventType: "all",
    });
    const [filters, setFilters] = useState({
        startTimestamp: null,
        endTimestamp: null,
        username: "all",
        eventType: "all",
    });
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [loadingQueue, setLoadingQueue] = useState(false);


    const fetchEvents = async (getSelectors, page, newFilters) => {
        try {
            setLoadingEvents(true);
            var response;
            if (newFilters) {
                setPrevFilters(filters);
                response = await getEvents(keycloak.token, filters, 0, getSelectors);
            } else {
                response = await getEvents(keycloak.token, prevFilters, page, getSelectors);
            }
            if (getSelectors) {
                setSelectors({
                    usernames: response.events.usernames,
                    eventTypes: response.events.eventTypes
                });
            }
            setPage(response.events.page);
            setExistsPreviousPage(response.events.hasPreviousPage);
            setExistsNextPage(response.events.hasNextPage);
            setData(response.events.events);
            setLoadingEvents(false);
        } catch (error) {
            setLoadingEvents(false);
            console.error("Error fetching data:", error);
        }
    }

    const fetchQueue = async () => {
        try {
            setLoadingQueue(true);
            const response = await getQueue(keycloak.token);
            setQueue(response.queue);
            setLoadingQueue(false);
        } catch (error) {
            setLoadingQueue(false);
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        fetchEvents(true, 0, true);
        fetchQueue();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Typography fontSize={{xs: "1.2em", md: "1.6em"}} mb={"10px"} variant="title">Historial</Typography>
                    <Typography fontSize={{xs: "0.8em", md: "1.0em"}} variant="title">Registro de acciones realizadas en la plataforma</Typography>
                </Grid>
                <Grid item xs={4} textAlign={"right"}>
                    {(queue && !loadingQueue) ?
                        <Box>
                            <Button startIcon={<FormatListNumberedIcon></FormatListNumberedIcon>} onClick={() => setQueueModal(true)} variant="red">Cola</Button>
                        </Box>
                        : loadingQueue ? <Box><CircularProgress sx={{ "color": "#d23831" }} /></Box>
                            : <></>
                    }
                </Grid>
            </Grid>
            {(selectors) ?
                <Grid container spacing={2}>
                    <Grid item xs={12} display={"flex"}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={2.5}>
                                <TextField
                                    label="Tipo de evento"
                                    variant="outlined"
                                    fullWidth
                                    value={filters.eventType}
                                    select
                                    sx={{ marginRight: "5px", minWidth: "200px" }}
                                    onChange={(event) => {
                                        setFilters({ ...filters, eventType: event.target.value });
                                    }}
                                    disabled={loadingEvents}
                                >
                                    <MenuItem value={"all"}>Todos</MenuItem>
                                    {selectors.eventTypes.map((e, index) => {
                                        return <MenuItem key={index} value={e}>{mapEvents(e).label}</MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={2.5}>
                                <TextField
                                    label="Usuario"
                                    value={filters.username}
                                    fullWidth
                                    select
                                    sx={{ marginRight: "5px", minWidth: "200px" }}
                                    onChange={(event) => {
                                        setFilters({ ...filters, username: event.target.value });
                                    }}
                                    disabled={loadingEvents}
                                >
                                    <MenuItem value={"all"}>Todos</MenuItem>
                                    {selectors.usernames.map((u, index) => {
                                        return <MenuItem key={index} value={u}>{u}</MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2.5}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                    <DateTimePicker
                                        label="Fecha mínima"
                                        value={filters.startTimestamp}
                                        fullWidth
                                        sx={{ marginRight: "5px", width: "100%" }}
                                        onChange={(date) => {
                                            setFilters({ ...filters, startTimestamp: date });
                                        }}
                                        slotProps={{
                                            textField: {
                                                disabled: loadingEvents
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2.5}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                    <DateTimePicker
                                        label="Fecha máxima"
                                        value={filters.endTimestamp}
                                        sx={{ marginRight: "5px", width: "100%" }}
                                        onChange={(date) => {
                                            setFilters({ ...filters, endTimestamp: date });
                                        }}
                                        slotProps={{
                                            textField: {
                                                disabled: loadingEvents
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={4} lg={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                <Button
                                    variant="red"
                                    onClick={() => {
                                        fetchEvents(false, 0, true);
                                    }}
                                    disabled={loadingEvents}
                                    startIcon={<SearchIcon />}
                                >Filtrar</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ backgroundColor: "#fff", padding: "10px" }}>
                            {data.length > 0 ? data.map((event, index) => (
                                <React.Fragment key={index}>
                                    <Typography fontSize={{ xs: "0.75em", md: "0.9em" }} color={mapEvents(event.event).color}>
                                        {event.timestamp.slice(0, 19).replace("T", " ")} — {event.username} — {mapEvents(event.event).label} — {event.data}
                                    </Typography>
                                    {index < data.length - 1 && <Divider sx={{ marginY: "5px", backgroundColor: "#e0e0e0" }} />}
                                </React.Fragment>
                            )) : (
                                <Typography textAlign={"center"} variant="p">No se encontraron eventos</Typography>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={6} textAlign={"right"}>
                        <IconButton disabled={!existsPreviousPage} onClick={() => fetchEvents(false, page - 1, false)}>
                            <NavigateBeforeIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} textAlign={"left"}>
                        <IconButton disabled={!existsNextPage} onClick={() => fetchEvents(false, page + 1, false)}>
                            <NavigateNextIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                : loadingEvents ? <Box textAlign={"center"} mt={"40px"}><CircularProgress /></Box>
                    : <></>
            }
            <QueueModal
                keycloak={keycloak}
                open={queueModal}
                handleClose={() => setQueueModal(false)}
                queue={queue}
                setQueue={setQueue}
                setAlertSuccess={setAlertSuccess}
                setAlertError={setAlertError}
                alerts={alerts}
                setAlerts={setAlerts}
            />
        </>
    )
}

export default Historial;