import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton, Menu, MenuItem, Link } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountIcon from '@mui/icons-material/AccountCircle';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ProfesorStatsModal from "../modals/ProfesorStatsModal";
import ProfesorProfileModal from "../modals/ProfesorProfileModal";
import ProfesorEditModal from '../modals/ProfesorEditModal';
import ProfesorDeleteModal from '../modals/ProfesorDeleteModal';
import { formatTimestamp } from '../../../utils/formatters';


const ProfesoresTable = ({
    keycloak,
    catalog,
    users,
    setUsers,
    regions,
    establecimientos,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 25, page: 0 });
    const [selectedUser, setSelectedUser] = useState(null);

    const [statsModal, setStatsModal] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const handleDownloadProfesoresCSV = () => {
        let header = [
            "RUT", 
            "Apellido paterno", 
            "Apellido materno", 
            "Nombre(s)", 
            "Método de contacto",
            "Teléfono", 
            "Email personal",
            "Género",
            "Título",
            "Región",
            "Comuna",
            "Staff",
            "Primer acceso",
            "Acceso más reciente",
            "Usuario en plataforma",
            "Correo en plataforma",
            "Nombre en plataforma",
            "Establecimientos",
            "Cursos"
        ];
        let lines = [];
        let output = [];
        for (let user of users) {
            var thisLine = [];
            thisLine.push(user.run ? user.run : "");
            thisLine.push(user.last_name_1 ? user.last_name_1 : "");
            thisLine.push(user.last_name_2 ? user.last_name_2 : "");
            thisLine.push(user.first_name ? user.first_name : "");
            thisLine.push(user.contact_method ? user.contact_method : "");
            thisLine.push(user.phone ? user.phone : "");
            thisLine.push(user.personal_email ? user.personal_email : "");
            thisLine.push(user.gender ? user.gender : "");
            thisLine.push(user.title ? user.title : "");
            thisLine.push(user.region ? user.region : "");
            thisLine.push(user.comuna ? user.comuna : "");
            thisLine.push(user.is_staff ? "Sí" : "No");
            thisLine.push(user.date_joined ? user.date_joined : "");
            thisLine.push(user.last_login ? user.last_login : "");
            thisLine.push(user.username ? user.username : "");
            thisLine.push(user.platform_email ? user.platform_email : "");
            thisLine.push(user.full_name ? user.full_name : "");
            thisLine.push(user.schools.map(school => school.rbd + ";" + school.date_start.slice(0, 10) + ";" + (school.date_end ? school.date_end.slice(0, 10) : "actual")).join(";;;"));
            thisLine.push(user.enrollments.map(enrollment => enrollment.course_key).join(";"));
            lines.push(thisLine);
        }
        output.push(header);
        for (let line of lines) {
            output.push(line);
        }
        const fileName = "profesores_" + new Date().getTime();
        const csv = Papa.unparse(output);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv"); 
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const columns = [
        {
            field: 'run',
            disableColumnMenu: true,
            headerName: 'RUT',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'last_name_1',
            disableColumnMenu: true,
            headerName: 'Apellido paterno',
            width: 160,
            headerAlign: 'center',
            align: 'left',
        },
        {
            field: 'last_name_2',
            disableColumnMenu: true,
            headerName: 'Apellido materno',
            width: 160,
            headerAlign: 'center',
            align: 'left',
        },
        {
            field: 'first_name',
            disableColumnMenu: true,
            headerName: 'Nombre(s)',
            width: 160,
            headerAlign: 'center',
            align: 'left',
        },
        {
            field: 'contact',
            disableColumnMenu: true,
            headerName: 'Contacto',
            sortable: false,
            width: 250,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    <Box display="flex" alignItems="center" gap={1}>
                        {params.row.contact_method === "email" && <EmailIcon fontSize="small" />}
                        {params.row.contact_method === "phone" && <PhoneIcon fontSize="small" />}
                        {params.row.contact_method === "whatsapp" && <WhatsAppIcon fontSize="small" />}
                        <Typography variant="body2">
                            {params.row.contact_method === "email" ? (
                                <Link href={`mailto:${params.row.platform_email}`}>{params.row.platform_email}</Link>
                            ) : params.row.contact_method === "phone" ? (
                                <Link href={`tel:${params.row.phone}`}>{params.row.phone}</Link>
                            ) : params.row.contact_method === "whatsapp" ? (
                                <Link href={`https://wa.me/${params.row.phone}`}>{params.row.phone}</Link>
                            ) : (
                                "—"
                            )}
                        </Typography>
                    </Box>
                </Box>
            ),
        },
        {
            field: 'enrolled_courses',
            disableColumnMenu: true,
            headerName: 'Cursos',
            width: 100,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'is_staff',
            disableColumnMenu: true,
            headerName: 'Staff',
            width: 80,
            headerAlign: 'center',
            align: 'center',
            sortComparator: (a, b) => {
                return a - b;
            },
            valueGetter: (params, row) => row == null ? "—" : row.is_staff == null ? "—" : row.is_staff,
            renderCell: (params) => (
                params.row.is_staff ?
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        <CheckCircleIcon sx={{ color: "green" }}>
                        </CheckCircleIcon>
                    </Box> : "—"
            ),
        },
        {
            field: 'detail',
            disableColumnMenu: true,
            headerName: 'Detalles',
            sortable: false,
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <ActionsMenu user={params.row} />
            ),
        },
    ];

    function makeRows() {
        let rows = [];
        for (let [key, value] of Object.entries(users)) {
            rows.push({
                id: value.id,
                username: value.username,
                platform_email: value.platform_email ? value.platform_email : "—",
                first_name: value.first_name ? value.first_name : "—",
                last_name_1: value.last_name_1 ? value.last_name_1 : "—",
                last_name_2: value.last_name_2 ? value.last_name_2 : "—",
                personal_email: value.personal_email ? value.personal_email : "—",
                phone: value.phone ? value.phone : "—",
                contact_method: value.contact_method ? value.contact_method : "—",
                gender: value.gender ? value.gender : "—",
                title: value.title ? value.title : "—",
                region: value.region ? value.region : "—",
                comuna: value.comuna ? value.comuna : "—",
                run: value.run ? value.run : "—",
                is_staff: value.is_staff,
                full_name: value.full_name ? value.full_name : "—",
                date_joined: value.date_joined ? formatTimestamp(value.date_joined) : "—",
                last_login: value.last_login ? formatTimestamp(value.last_login) : "—",
                schools: value.schools,
                enrollments: value.enrollments,
                enrolled_courses: value.enrollments.length
            });
        }
        return rows;
    }

    const ActionsMenu = ({ user }) => {

        const [anchorEl, setAnchorEl] = useState(null);

        const handleClickActions = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleCloseActions = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <IconButton
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={handleClickActions}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseActions}
                >
                    <MenuItem
                        onClick={() => {
                            setSelectedUser(user);
                            setProfileModal(true);
                        }}
                    >
                        <AccountIcon sx={{ "marginRight": "10px" }} /> Perfil completo
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setSelectedUser(user);
                            setEditModal(true);
                        }}
                    >
                        <EditIcon sx={{ "marginRight": "10px" }} /> Editar
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setSelectedUser(user);
                            setDeleteModal(true);
                        }}
                    >
                        <DeleteForeverIcon sx={{ "marginRight": "10px" }} /> Eliminar
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Lista de cuentas de profesores con acceso a la plataforma</Typography>
                <Box>
                    <IconButton onClick={() => setStatsModal(true)}><InsertChartIcon></InsertChartIcon></IconButton>
                    <IconButton onClick={handleDownloadProfesoresCSV}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        Object.keys(users).length === 0 ?
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant='p-error' textAlign={"center"}>No hay datos de profesores disponibles.</Typography>
            </Box>
            :
            <div style={{ maxWidth: '1120px' }}>
                <DataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[25]}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'id',
                                    sort: 'asc',
                                },
                            ],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
                <ProfesorStatsModal
                    open={statsModal}
                    handleClose={() => setStatsModal(false)}
                    users={users}
                />
                {selectedUser &&
                    <>
                        <ProfesorProfileModal
                            keycloak={keycloak}
                            open={profileModal}
                            handleClose={() => setProfileModal(false)}
                            selectedUser={selectedUser}
                            catalog={catalog}
                            regions={regions}
                            establecimientos={establecimientos}
                        />
                        <ProfesorEditModal
                            keycloak={keycloak}
                            open={editModal}
                            handleClose={() => setEditModal(false)}
                            selectedUser={selectedUser}
                            setUsers={setUsers}
                            regions={regions}
                            establecimientos={establecimientos}
                            setAlertError={setAlertError}
                            setAlertSuccess={setAlertSuccess}
                            alerts={alerts}
                            setAlerts={setAlerts}
                        />
                        <ProfesorDeleteModal
                            keycloak={keycloak}
                            open={deleteModal}
                            handleClose={() => setDeleteModal(false)}
                            selectedUser={selectedUser}
                            setUsers={setUsers}
                            setAlertError={setAlertError}
                            setAlertSuccess={setAlertSuccess}
                            alerts={alerts}
                            setAlerts={setAlerts}
                        />
                    </>
                }
            </div>
    );
};

export default ProfesoresTable;