import React from "react";
import { Typography, Grid, Button } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';


const DiplomadoSectionsAll = ({
    keycloak,
    diplomado,
    selectedModule,
    selectedImplementation,
    loadingSectionData,
    setLoadingSectionData,
}) => {

    return (
        <Grid container spacing={2} mt={"5px"}>
            <Grid item xs={12}>
                <Typography fontSize={{xs: "0.8em", sm: "0.9em", md: "1.0em", lg: "1.2em"}} variant="title">
                    Implementación {diplomado.modules[selectedModule]?.implementations[selectedImplementation]?.year}-{diplomado.modules[selectedModule]?.implementations[selectedImplementation]?.term} del módulo {diplomado.modules[selectedModule]?.module} del diplomado {diplomado.shortname}</Typography>
            </Grid>
        </Grid>
    );
}

export default DiplomadoSectionsAll;