import React, { useState } from 'react';
import { Grid, MenuItem, TextField, Dialog, DialogContent, DialogTitle, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { postDiplomado } from '../../../../../../requests/catalog/postDiplomado';


const CatalogDiplomadoCreateModal = ({
    keycloak,
    open,
    handleClose,
    catalog,
    setCatalog,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [diplomadoShortname, setDiplomadoShortname] = useState("");
    const [diplomadoName, setDiplomadoName] = useState("");
    const [diplomadoDescription, setDiplomadoDescription] = useState("");
    const [diplomadoCycle, setDiplomadoCycle] = useState("none");
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let errors = {};
        if (diplomadoShortname === "") {
            errors.diplomadoShortname = "Por favor ingrese una sigla.";
        } else if (diplomadoShortname.length !== 3) {
            errors.diplomadoShortname = "La sigla debe tener 3 caracteres.";
        } else if (diplomadoShortname !== diplomadoShortname.toUpperCase()) {
            errors.diplomadoShortname = "La sigla debe ser en mayúsculas.";
        } else if (catalog.diplomados.some(diplomado => diplomado.shortname === diplomadoShortname)) {
            errors.diplomadoShortname = "Ya existe un diplomado con esta sigla.";
        }
        if (diplomadoName === "") {
            errors.diplomadoName = "Por favor ingrese un nombre.";
        } else if (diplomadoName.length < 5 || diplomadoName.length > 100) {
            errors.diplomadoName = "El nombre debe tener entre 5 y 100 caracteres.";
        } else if (catalog.diplomados.some(diplomado => diplomado.name === diplomadoName)) {
            errors.diplomadoName = "Ya existe un diplomado con este nombre.";
        }
        if (diplomadoDescription === "") {
            errors.diplomadoDescription = "Por favor ingrese una descripción.";
        } else if (diplomadoDescription.length < 10 || diplomadoDescription.length > 2000) {
            errors.diplomadoDescription = "La descripción debe tener entre 10 y 2000 caracteres.";
        }
        if (diplomadoCycle === "none") {
            errors.diplomadoCycle = "Por favor seleccione un ciclo.";
        }
        return errors;
    }

    const handleCreateDiplomado = async () => {
        setErrors({});
        let validateErrors = validateForm();
        if (Object.keys(validateErrors).length !== 0) {
            setErrors(validateErrors);
            return;
        }
        setFormLoading(true);
        let createData = {
            "shortname": diplomadoShortname,
            "name": diplomadoName,
            "description": diplomadoDescription,
            "cycle": diplomadoCycle
        }
        let response = await postDiplomado(keycloak.token, createData);
        if (response.catalog == null) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al crear diplomado", "text": response.error } });
        } else {
            setCatalog(response.catalog);
            handleClose();
            setDiplomadoShortname("");
            setDiplomadoName("");
            setDiplomadoDescription("");
            setDiplomadoCycle("none");
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Diplomado creado!`, "text": `El nuevo diplomado ha sido creado.` } });
        }
        setFormLoading(false);
    }

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.2em" }}>
                Crear diplomado
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label="Sigla"
                            variant="outlined"
                            value={diplomadoShortname}
                            onChange={(e) => {
                                setDiplomadoShortname(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, diplomadoShortname: "" }))
                            }}
                            error={!!errors.diplomadoShortname}
                            helperText={errors.diplomadoShortname}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField
                            fullWidth
                            label="Nombre"
                            variant="outlined"
                            value={diplomadoName}
                            onChange={(e) => {
                                setDiplomadoName(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, diplomadoName: "" }))
                            }}
                            error={!!errors.diplomadoName}
                            helperText={errors.diplomadoName}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Descripción"
                            variant="outlined"
                            multiline
                            rows={6}
                            value={diplomadoDescription}
                            onChange={(e) => {
                                setDiplomadoDescription(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, diplomadoDescription: "" }))
                            }}
                            error={!!errors.diplomadoDescription}
                            helperText={errors.diplomadoDescription}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Ciclo"
                            variant="outlined"
                            value={diplomadoCycle}
                            select
                            onChange={(e) => {
                                setDiplomadoCycle(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, diplomadoCycle: "" }))
                            }}
                            error={!!errors.diplomadoCycle}
                            helperText={errors.diplomadoCycle}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"1"}>Primer ciclo</MenuItem>
                            <MenuItem value={"2"}>Segundo ciclo</MenuItem>
                            <MenuItem value={"12"}>Primer y segundo ciclo</MenuItem>
                            <MenuItem value={"3"}>Media</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button startIcon={<AddCircleOutlineIcon />} variant='blue' onClick={handleCreateDiplomado} disabled={formLoading}>
                            Crear diplomado
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CatalogDiplomadoCreateModal;