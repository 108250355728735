import React, { useEffect, useState } from "react";
import { Typography, Grid, TextField, MenuItem, Box, CircularProgress } from "@mui/material";
import DiplomadoModulesAll from "../components/diplomado/DiplomadoModulesAll";
import DiplomadoImplementationsAll from "../components/diplomado/DiplomadoImplementationsAll";
import DiplomadoSectionsAll from "../components/diplomado/DiplomadoSectionsAll";
import DiplomadoSection from "../components/diplomado/DiplomadoSection";
import { getCourseSectionData } from "../../requests/getCourseSectionData";


const Diplomado = ({
    keycloak,
    diplomado,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [selectedModule, setSelectedModule] = useState("all");
    const [selectedImplementation, setSelectedImplementation] = useState("all");
    const [selectedSection, setSelectedSection] = useState("all");
    const [loadingDiplomado, setLoadingDiplomado] = useState(false);
    const [loadingSectionData, setLoadingSectionData] = useState(false);

    const [diplomadoData, setDiplomadoData] = useState(null);
    const [implementationData, setImplementationData] = useState(null);
    const [sectionData, setSectionData] = useState(null);

    const getAllModulesData = () => {

    }

    const getAllImplementationsData = () => {

    }

    const getAllSectionsData = () => {

    }

    const getSectionData = async () => {
        if (
            diplomado &&
            diplomado.modules[selectedModule].implementations[selectedImplementation] &&
            diplomado.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection]
        ) {
            setLoadingSectionData(true);
            const section = diplomado.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection];
            const response = await getCourseSectionData(keycloak.token, section.course_key, "diplomado", diplomado.modules[selectedModule].implementations[selectedImplementation].platform);

            if (response.data) {
                console.log(response.data);
                setSectionData(response.data);
            } else {
                setAlertError(true);
                setAlerts({
                    ...alerts,
                    error: { title: "Error al obtener datos de la sección", text: response.error },
                });
            }
            setLoadingSectionData(false);
        }
    };

    const getData = async () => {
        if (selectedModule === "all") {
            getAllModulesData();
        } else if (selectedImplementation === "all") {
            getAllImplementationsData();
        } else if (selectedSection === "all") {
            getAllSectionsData();
        } else {
            getSectionData();
        }
    }

    useEffect(() => {
        setLoadingDiplomado(true);
        setSelectedModule("all");
        setSelectedImplementation("all");
        setSelectedSection("all");
        setSectionData(null);
        setImplementationData(null);
        setDiplomadoData(null);
        setLoadingDiplomado(false);
    }, [diplomado]);

    useEffect(() => {
        if (!loadingDiplomado && selectedModule !== null && selectedImplementation !== null && selectedSection !== null) {
            getData();
        }
    }, [selectedModule, selectedImplementation, selectedSection]);

    return (
        !loadingDiplomado &&
        <>
            <Typography fontSize={{ xs: "1.2em", md: "1.6em" }} mb={"10px"} variant="title">{diplomado.name}</Typography>
            <Typography fontSize={{ xs: "0.8em", md: "1.0em" }} variant="title">Datos del diplomado {diplomado.shortname}</Typography>
            <Grid container spacing={2} display={"flex"} justifyContent={"start"}>
                <Grid item xs={12} md={4} lg={3} display={"flex"}>
                    <TextField
                        label="Módulo"
                        variant="outlined"
                        fullWidth
                        value={selectedModule}
                        select
                        disabled={loadingSectionData}
                        sx={{ marginRight: "5px", minWidth: "200px" }}
                        onChange={(event) => {
                            setSelectedModule(event.target.value);
                            setSelectedImplementation("all");
                            setSelectedSection("all");
                        }}
                    >
                        <MenuItem value={"all"}>Todos</MenuItem>
                        {diplomado.modules && diplomado.modules.map((mod, index) => {
                            return <MenuItem key={index} value={index}>{mod.module + " - " + mod.name}</MenuItem>
                        })}
                    </TextField>
                </Grid>
                {selectedModule !== "all" &&
                    <Grid item xs={12} md={4} lg={3} display={"flex"}>
                        <TextField
                            label="Implementación"
                            variant="outlined"
                            fullWidth
                            value={selectedImplementation}
                            select
                            disabled={loadingSectionData}
                            sx={{ marginRight: "5px", minWidth: "200px" }}
                            onChange={(event) => {
                                setSelectedImplementation(event.target.value);
                                setSelectedSection("all");
                            }}
                        >
                            <MenuItem value={"all"}>Todas</MenuItem>
                            {diplomado.modules[selectedModule]?.implementations.map((imp, index) => {
                                return <MenuItem key={index} value={index}>{imp.year + "-" + imp.term}</MenuItem>
                            })}
                        </TextField>
                    </Grid>
                }
                {(selectedModule !== "all" && selectedImplementation !== "all") &&
                    <Grid item xs={12} md={4} lg={3} display={"flex"}>
                        <TextField
                            label="Sección"
                            variant="outlined"
                            fullWidth
                            value={selectedSection}
                            select
                            disabled={loadingSectionData}
                            sx={{ marginRight: "5px", minWidth: "200px" }}
                            onChange={(event) => {
                                setSelectedSection(event.target.value);
                            }}
                        >
                            <MenuItem value={"all"}>Todas</MenuItem>
                            {diplomado.modules[selectedModule]?.implementations[selectedImplementation]?.sections?.map((sec, index) => {
                                return <MenuItem key={index} value={index}>{sec.section}</MenuItem>
                            })}
                        </TextField>
                    </Grid>
                }
            </Grid>
            {loadingSectionData ?
                <Box width={"100%"} textAlign={"center"} mt={"50px"}>
                    <CircularProgress />
                </Box>
                : selectedModule === "all" ?
                    <DiplomadoModulesAll
                        keycloak={keycloak}
                        diplomado={diplomado}
                        loadingSectionData={loadingSectionData}
                        setLoadingSectionData={setLoadingSectionData}
                    />
                    : selectedImplementation === "all" ?
                        <DiplomadoImplementationsAll
                            keycloak={keycloak}
                            diplomado={diplomado}
                            selectedModule={selectedModule}
                            loadingSectionData={loadingSectionData}
                            setLoadingSectionData={setLoadingSectionData}
                        />
                        : selectedSection === "all" ?
                            <DiplomadoSectionsAll
                                keycloak={keycloak}
                                diplomado={diplomado}
                                selectedModule={selectedModule}
                                selectedImplementation={selectedImplementation}
                                loadingSectionData={loadingSectionData}
                                setLoadingSectionData={setLoadingSectionData}
                            />
                            :
                            <DiplomadoSection
                                keycloak={keycloak}
                                diplomado={diplomado}
                                selectedModule={selectedModule}
                                selectedImplementation={selectedImplementation}
                                selectedSection={selectedSection}
                                loadingSectionData={loadingSectionData}
                                sectionData={sectionData}
                                setAlertSuccess={setAlertSuccess}
                                setAlertError={setAlertError}
                                alerts={alerts}
                                setAlerts={setAlerts}
                            />
            }
        </>
    );
}

export default Diplomado;