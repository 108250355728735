export const formatFileSize = (size) => {
    if (size === 0) {
        return "0";
    }
    if (size < 1024) return size + ' Bytes';
    let i = Math.floor(Math.log(size) / Math.log(1024));
    let num = (size / Math.pow(1024, i));
    let round = Math.round(num);
    num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
    return `${num} ${['Bytes', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

export const formatTimestamp = (timestamp) => {
    const date = isNaN(Number(timestamp)) ? new Date(timestamp) : new Date(Number(timestamp));
    const formatter = new Intl.DateTimeFormat('es-ES', {
        year: 'numeric', month: 'long', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
        hour12: false
    });
    return formatter.format(date);
}

export function capitalizeFirstLetter(string) {
    return string
        .split(' ') // Split the string into an array of words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter, make the rest lowercase
        .join(' '); // Join the words back into a single string
}