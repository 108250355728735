import React, { useEffect, useState } from "react";
import { Typography, Grid, TextField, MenuItem, Box, CircularProgress } from "@mui/material";
import CourseImplementationsAll from "../components/course/CourseImplementationsAll";
import CourseSectionsAll from "../components/course/CourseSectionsAll";
import CourseSection from "../components/course/CourseSection";
import { getCourseSectionData } from "../../requests/getCourseSectionData";


const Course = ({
    keycloak,
    course,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [selectedImplementation, setSelectedImplementation] = useState("all");
    const [selectedSection, setSelectedSection] = useState("all");
    const [loadingCourse, setLoadingCourse] = useState(false);
    const [loadingSectionData, setLoadingSectionData] = useState(false);

    const [courseData, setCourseData] = useState(null);
    const [implementationData, setImplementationData] = useState(null);
    const [sectionData, setSectionData] = useState(null);

    const getAllImplementationsData = () => {

    }

    const getAllSectionsData = () => {

    }

    const getSectionData = async () => {
        if (
            course &&
            course.implementations[selectedImplementation] &&
            course.implementations[selectedImplementation].sections[selectedSection]
        ) {
            setLoadingSectionData(true);
            const section = course.implementations[selectedImplementation].sections[selectedSection];
            const response = await getCourseSectionData(keycloak.token, section.course_key, "course", course.implementations[selectedImplementation].platform);

            if (response.data) {
                console.log(response.data);
                setSectionData(response.data);
            } else {
                setAlertError(true);
                setAlerts({
                    ...alerts,
                    error: { title: "Error al obtener datos de la sección", text: response.error },
                });
            }
            setLoadingSectionData(false);
        }
    };

    const getData = async () => {
        if (selectedImplementation === "all") {
            getAllImplementationsData();
        } else if (selectedSection === "all") {
            getAllSectionsData();
        } else {
            getSectionData();
        }
    }

    useEffect(() => {
        setLoadingCourse(true);
        setSelectedImplementation("all");
        setSelectedSection("all");
        setSectionData(null);
        setImplementationData(null);
        setCourseData(null);
        setLoadingCourse(false);
    }, [course]);

    useEffect(() => {
        if (!loadingCourse && selectedImplementation !== null && selectedSection !== null) {
            getData();
        }
    }, [selectedImplementation, selectedSection]);

    return (
        !loadingCourse &&
        <>
            <Typography fontSize={{xs: "1.2em", md: "1.6em"}} mb={"10px"} variant="title">{course.name}</Typography>
            <Typography fontSize={{xs: "0.8em", md: "1.0em"}} variant="title">Datos del curso {course.shortname}</Typography>
            <Grid container spacing={2} display={"flex"} justifyContent={"start"}>
                <Grid item xs={12} sm={6} md={4} lg={3} display={"flex"}>
                    <TextField
                        label="Implementación"
                        variant="outlined"
                        fullWidth
                        value={selectedImplementation}
                        select
                        disabled={loadingSectionData}
                        sx={{ marginRight: "5px", minWidth: "200px" }}
                        onChange={(event) => {
                            setSelectedImplementation(event.target.value);
                            setSelectedSection("all");
                        }}
                    >
                        <MenuItem value={"all"}>Todas</MenuItem>
                        {course.implementations && course.implementations.map((imp, index) => {
                            return <MenuItem key={index} value={index}>{imp.year + "-" + imp.term + " (" + imp.type + ")"}</MenuItem>
                        })}
                    </TextField>
                </Grid>
                {(selectedImplementation !== "all") &&
                    <Grid item xs={12} sm={6} md={4} lg={3} display={"flex"}>
                        <TextField
                            label="Sección"
                            variant="outlined"
                            fullWidth
                            value={selectedSection}
                            select
                            disabled={loadingSectionData}
                            sx={{ marginRight: "5px", minWidth: "200px" }}
                            onChange={(event) => {
                                setSelectedSection(event.target.value);
                            }}
                        >
                            <MenuItem value={"all"}>Todas</MenuItem>
                            {course.implementations[selectedImplementation]?.sections?.map((sec, index) => {
                                return <MenuItem key={index} value={index}>{sec.location + sec.section}</MenuItem>
                            })}
                        </TextField>
                    </Grid>
                }
            </Grid>
            {loadingSectionData ?
                <Box width={"100%"} textAlign={"center"} mt={"50px"}>
                    <CircularProgress />
                </Box>
                : selectedImplementation === "all" ?
                    <CourseImplementationsAll
                        keycloak={keycloak}
                        course={course}
                        loadingSectionData={loadingSectionData}
                        setLoadingSectionData={setLoadingSectionData}
                    />
                    : selectedSection === "all" ?
                        <CourseSectionsAll
                            keycloak={keycloak}
                            course={course}
                            selectedImplementation={selectedImplementation}
                            loadingSectionData={loadingSectionData}
                            setLoadingSectionData={setLoadingSectionData}
                        />
                        :
                        <CourseSection
                            keycloak={keycloak}
                            course={course}
                            selectedImplementation={selectedImplementation}
                            selectedSection={selectedSection}
                            loadingSectionData={loadingSectionData}
                            sectionData={sectionData}
                            setAlertSuccess={setAlertSuccess}
                            setAlertError={setAlertError}
                            alerts={alerts}
                            setAlerts={setAlerts}
                        />
            }
        </>
    );
}

export default Course;