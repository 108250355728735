import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const LineChart = ({ id, data, lineColor, title, height, labelAngle, startDate, endDate, timeInterval, tooltipFormatter }) => {
    useEffect(() => {
        const rawData = typeof data === 'function' ? data() : data;

        const parsedData = rawData.map(item => ({
            ...item,
            date: new Date(item.date.slice(0, 4), item.date.slice(4, 6) - 1, item.date.slice(6, 8))
        }));

        const getDateKey = (date, interval) => {
            if (interval === 'week') {
                const startOfWeek = new Date(date);
                startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
                return startOfWeek.toISOString().split('T')[0];
            } else if (interval === 'month') {
                return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-01`;
            }
            return date.toISOString().split('T')[0];
        };

        const aggregateData = (data, interval) => {
            const aggregate = {};
            const start = startDate ? new Date(startDate) : new Date(Math.min(...data.map(item => item.date)));
            const end = endDate ? new Date(endDate) : new Date(Math.max(...data.map(item => item.date)));

            for (let date = new Date(start); date <= end; date.setDate(date.getDate() + (interval === 'day' ? 1 : (interval === 'week' ? 7 : 30)))) {
                let key = getDateKey(date, interval);
                if (!aggregate[key]) {
                    aggregate[key] = { date: new Date(key), value: 0 };
                }
            }

            data.forEach(item => {
                let key = getDateKey(item.date, interval);
                if (!aggregate[key]) {
                    aggregate[key] = { ...item, date: new Date(key), value: 0 };
                }
                aggregate[key].value += item.value;
            });

            return Object.values(aggregate).map(item => ({
                ...item,
                value: Math.round(item.value)
            }));
        };

        const chartData = aggregateData(parsedData, timeInterval);

        let chart = am4core.create(id, am4charts.XYChart);
        chart.data = chartData;

        let plotTitle = chart.titles.create();
        plotTitle.paddingBottom = 10;
        plotTitle.text = title;
        plotTitle.fill = am4core.color("#646464");

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 50;
        dateAxis.renderer.labels.template.fill = am4core.color("#646464");
        dateAxis.renderer.labels.template.fontSize = 12;
        if (labelAngle && labelAngle !== 0) {
            dateAxis.renderer.labels.template.rotation = labelAngle;
            dateAxis.renderer.labels.template.horizontalCenter = "right";
            dateAxis.renderer.labels.template.verticalCenter = "middle";
        } else {
            dateAxis.renderer.labels.template.rotation = 0;
        }

        dateAxis.baseInterval = {
            "timeUnit": timeInterval,
            "count": 1
        };

        dateAxis.dateFormats.setKey("day", "MMM dd");
        dateAxis.periodChangeDateFormats.setKey("day", "MMM dd");
        dateAxis.dateFormats.setKey("week", "MMM dd");
        dateAxis.periodChangeDateFormats.setKey("week", "MMM dd");
        dateAxis.dateFormats.setKey("month", "MMM yyyy");
        dateAxis.periodChangeDateFormats.setKey("month", "MMM yyyy");
        dateAxis.minZoomCount = 1;

        if (startDate) {
            dateAxis.min = new Date(startDate).getTime();
        }
        if (endDate) {
            dateAxis.max = new Date(endDate).getTime();
        }

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.minGridDistance = 30;
        valueAxis.renderer.ticks.template.length = 15;
        valueAxis.renderer.ticks.template.strokeOpacity = 1;
        valueAxis.renderer.ticks.template.stroke = am4core.color("#000");
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.minGridDistance = 50;
        valueAxis.renderer.numberFormatter.numberFormat = "#";
        valueAxis.renderer.labels.template.fill = am4core.color("#646464");
        valueAxis.renderer.labels.template.fontSize = 12;

        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = 'value';
        series.dataFields.dateX = 'date';
        series.tooltipText = '{dateX}: [bold]{valueY}[/]';
        series.strokeWidth = 2;
        series.stroke = am4core.color(lineColor);
        series.fill = am4core.color(lineColor);
        series.fillOpacity = 0.8;

        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 1;
        bullet.circle.radius = 3; // Make the circles smaller
        bullet.circle.fill = am4core.color(lineColor);
        bullet.circle.stroke = am4core.color(lineColor);

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.stroke = am4core.color(lineColor);
        chart.cursor.lineY.stroke = am4core.color(lineColor);

        series.adapter.add("tooltipText", (text, target) => {
            return tooltipFormatter ? tooltipFormatter(text) : text;
        });

        return () => {
            chart.dispose();
        };
    }, [data, id, title, labelAngle, startDate, endDate, timeInterval, tooltipFormatter]);

    return <div id={id} style={{ width: '100%', height: height }}></div>;
};

export default LineChart;