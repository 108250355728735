import React, { useState } from 'react';
import { Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { postDeleteTaskFromQueue } from '../../../requests/postDeleteTaskFromQueue';


const QueueDeleteModal = ({
    keycloak,
    open,
    handleClose,
    selectedTask,
    setQueue,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [formLoading, setFormLoading] = useState(false);

    const handleCancelTask = async () => {
        setFormLoading(true);
        let deleteData = {
            "course_key": selectedTask.course_key,
            "course_platform": selectedTask.course_platform,
            "course_type": selectedTask.course_type
        }
        let response = await postDeleteTaskFromQueue(keycloak.token, deleteData)
        console.log(response)
        if (!response.queue.deleted) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al cancelar tarea", "text": "No se encontró la tarea. ¿Quizás ya inició o ya fue cancelada?" } });
        } else {
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Tarea cancelada!`, "text": `La actualización del curso ${selectedTask.course_key} se ha cancelado correctamente.` } }); 
        }
        handleClose();
        setQueue(response.queue.queue);
        setFormLoading(false);
    }

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                Cancelar tarea
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography fontSize={"0.8em"} mb={"10px"} variant="p">
                            ¿Está seguro que desea eliminar de la cola la actualización de este
                            curso?
                        </Typography>
                        <Typography textAlign={"center"} fontSize={"0.8em"} mb={"10px"} variant="p">
                            <b>{selectedTask.course_key}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button startIcon={<DeleteForeverIcon />} variant='red' onClick={handleCancelTask} disabled={formLoading}>
                            Cancelar tarea
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default QueueDeleteModal;