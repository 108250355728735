import React, { useState, useEffect } from "react";
import { Typography, Grid, Card, CardContent, IconButton, Box } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CatalogDiplomadoImplementationEditModal from '../modals/catalog/diplomado/crud/CatalogDiplomadoImplementationEditModal';
import CatalogDiplomadoImplementationDeleteModal from '../modals/catalog/diplomado/crud/CatalogDiplomadoImplementationDeleteModal';
import CatalogDiplomadoSectionsModal from '../modals/catalog/diplomado/CatalogDiplomadoSectionsModal';


const CatalogDiplomadoImplementation = ({
    keycloak,
    diplomadoIndex,
    moduleIndex,
    implementationIndex,
    catalog,
    setCatalog,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {


    const [implementationData, setImplementationData] = useState(null);
    const [otherImplementations, setOtherImplementations] = useState(null);

    const [showSectionsModal, setShowSectionsModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleOpenSectionsModal = () => {
        setShowSectionsModal(true);
    }

    const handleCloseSectionsModal = () => {
        setShowSectionsModal(false);
    }

    const handleOpenEditModal = () => {
        setShowEditModal(true);
    }

    const handleCloseEditModal = () => {
        setShowEditModal(false);
    }

    const handleOpenDeleteModal = () => {
        setShowDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    }

    useEffect(() => {
        setImplementationData(catalog.diplomados[diplomadoIndex].modules[moduleIndex].implementations[implementationIndex]);
        setOtherImplementations(catalog.diplomados[diplomadoIndex].modules[moduleIndex].implementations.filter(s => s !== catalog.diplomados[diplomadoIndex].modules[moduleIndex].implementations[implementationIndex]));
    }, [catalog]);


    return (
        implementationData &&
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#efefef' }}>
            <CardContent sx={{ flexGrow: 1 }}>
                <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography mb={"0px"} fontSize={"1.0em"} variant="title">Implementación {implementationData.year}-{implementationData.term}</Typography>
                    <Box display={"flex"}>
                        <IconButton sx={{ width: 20, height: 20, marginRight: "5px" }} onClick={() => handleOpenSectionsModal()}>
                            <ListIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton sx={{ width: 20, height: 20, marginRight: "5px" }} onClick={() => handleOpenEditModal()}>
                            <EditIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton sx={{ width: 20, height: 20 }} onClick={() => handleOpenDeleteModal()}>
                            <DeleteForeverIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                    </Box>
                </Grid>
            </CardContent>
            <Grid container spacing={2} sx={{ padding: '0 20px 20px 20px', alignSelf: 'flex-end' }}>
                <Grid item xs={12}>
                    <Typography fontSize={"0.8em"} variant="p">
                        <b><u>Participantes</u></b>: {0}
                    </Typography>
                    <Typography fontSize={"0.8em"} variant="p">
                        <b><u>Respuestas</u></b>: {0}
                    </Typography>
                </Grid>
            </Grid>
            <>
                <CatalogDiplomadoImplementationEditModal
                    keycloak={keycloak}
                    open={showEditModal}
                    handleClose={handleCloseEditModal}
                    diplomadoIndex={diplomadoIndex}
                    moduleIndex={moduleIndex}
                    implementationIndex={implementationIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    otherImplementations={otherImplementations}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
                <CatalogDiplomadoImplementationDeleteModal
                    keycloak={keycloak}
                    open={showDeleteModal}
                    handleClose={handleCloseDeleteModal}
                    diplomadoIndex={diplomadoIndex}
                    moduleIndex={moduleIndex}
                    implementationIndex={implementationIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                /> 
                <CatalogDiplomadoSectionsModal
                    keycloak={keycloak}
                    open={showSectionsModal}
                    handleClose={handleCloseSectionsModal}
                    diplomadoIndex={diplomadoIndex}
                    moduleIndex={moduleIndex}
                    implementationIndex={implementationIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
            </>
        </Card>
    );

}

export default CatalogDiplomadoImplementation;