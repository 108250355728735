import axios from 'axios';

export const postDeleteTaskFromQueue = async (token, data) => {

    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + "/delete_from_queue", data, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });

        return { queue: response.data, error: "" };

    } catch (error) {
        return { queue: null, error: "Error desconocido: " + error.message };
    }
}