import React, { useState } from 'react';
import { Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { deleteDiplomadoModule } from '../../../../../../requests/catalog/deleteDiplomadoModule';


const CatalogDiplomadoModuleDeleteModal = ({ 
    keycloak,
    open, 
    handleClose, 
    diplomadoIndex,
    moduleIndex,
    catalog,
    setCatalog,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [formLoading, setFormLoading] = useState(false);

    const handleDeleteModule = async () => {
        setFormLoading(true);
        let response = await deleteDiplomadoModule(keycloak.token, catalog.diplomados[diplomadoIndex].modules[moduleIndex]._id);
        if (response.catalog == null) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al eliminar módulo", "text": response.error } });
        } else {
            setCatalog(response.catalog);
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Módulo eliminado!`, "text": `El módulo ${catalog.diplomados[diplomadoIndex].modules[moduleIndex].module} del diplomado ${catalog.diplomados[diplomadoIndex].shortname} se ha eliminado correctamente.` } });
            handleClose();
        }
        setFormLoading(false);
    }

    return (
        catalog.diplomados[diplomadoIndex].modules[moduleIndex] &&
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.2em" }}>
                Eliminar módulo
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography fontSize={"0.8em"} mb={"10px"} variant="p">
                            ¿Está seguro que desea eliminar el módulo {catalog.diplomados[diplomadoIndex].modules[moduleIndex].module} del 
                            diplomado {catalog.diplomados[diplomadoIndex].shortname}? Se eliminarán todas las implementaciones y secciones asociadas.
                        </Typography>
                        <Typography textAlign={"center"} fontSize={"0.8em"} mb={"10px"} variant="p">
                            <b>Esta acción es destructiva y no se puede deshacer.</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button startIcon={<DeleteForeverIcon />} variant='red' onClick={handleDeleteModule} disabled={formLoading}>
                            Eliminar módulo
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CatalogDiplomadoModuleDeleteModal;