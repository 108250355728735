import React, { useState } from 'react';
import { Grid, MenuItem, TextField, Dialog, DialogContent, DialogTitle, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { postDiplomadoImplementation } from '../../../../../../requests/catalog/postDiplomadoImplementation';


const CatalogDiplomadoImplementationCreateModal = ({
    keycloak,
    open, 
    handleClose,
    catalog,
    setCatalog,
    diplomadoIndex,
    moduleIndex,
    otherImplementations,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [implementationYear, setImplementationYear] = useState("");
    const [implementationTerm, setImplementationTerm] = useState("none");
    const [implementationPlatform, setImplementationPlatform] = useState("none");
    const [errors, setErrors] = useState({});
    const [formLoading, setFormLoading] = useState(false);

    const validateForm = () => {
        let errors = {};
        if (implementationYear === "") {
            errors.implementationYear = "Por favor ingrese un año.";
        } else if (isNaN(implementationYear)) {
            errors.implementationYear = "Por favor ingrese un año válido.";
        } else if (implementationYear.length !== 4) {
            errors.implementationYear = "Por favor ingrese un año válido.";
        }
        if (implementationTerm === "none") {
            errors.implementationTerm = "Por favor seleccione un semestre.";
        }
        if (implementationPlatform === "none") {
            errors.implementationPlatform = "Por favor seleccione una plataforma.";
        }
        if (otherImplementations.some(impl => impl.year === implementationYear && impl.term === implementationTerm)) {
            errors.implementationYear = "Ya existe una implementación con estos datos.";
            errors.implementationTerm = "Ya existe una implementación con estos datos.";
            errors.implementationType = "Ya existe una implementación con estos datos.";
        }
        return errors;
    }
            
    const handleCreateImplementation = async () => {
        setErrors({});
        let validateErrors = validateForm();
        if (Object.keys(validateErrors).length !== 0) {
            setErrors(validateErrors);
            return;
        }
        setFormLoading(true);
        let createData = {
            "diplomado_module_id": catalog.diplomados[diplomadoIndex].modules[moduleIndex]._id,
            "year": implementationYear,
            "term": implementationTerm,
            "platform": implementationPlatform
        }
        let response = await postDiplomadoImplementation(keycloak.token, createData);
        if (response.catalog == null) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al crear implementación", "text": response.error } });
        } else {
            setCatalog(response.catalog);
            handleClose();
            setImplementationYear("");
            setImplementationTerm("none");
            setImplementationPlatform("none");
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Implementación creada!`, "text": `La nueva implementación del módulo ${catalog.diplomados[diplomadoIndex].modules[moduleIndex].module} del diplomado ${catalog.diplomados[diplomadoIndex].shortname} se ha creado correctamente.` } });
        }
        setFormLoading(false);
    }

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.2em" }}>
                Nueva implementación de {catalog.diplomados[diplomadoIndex].modules[moduleIndex].module} de {catalog.diplomados[diplomadoIndex].shortname}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Año"
                            variant="outlined"
                            value={implementationYear}
                            onChange={(e) => {
                                setImplementationYear(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, implementationYear: "" }))
                            }}
                            error={!!errors.implementationYear}
                            helperText={errors.implementationYear}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Semestre"
                            variant="outlined"
                            select
                            value={implementationTerm}
                            onChange={(e) => {
                                setImplementationTerm(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, implementationTerm: "" }))
                            }}
                            error={!!errors.implementationTerm}
                            helperText={errors.implementationTerm}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"0"}>Anual</MenuItem>
                            <MenuItem value={"1"}>Primer semestre</MenuItem>
                            <MenuItem value={"2"}>Segundo semestre</MenuItem>
                            <MenuItem value={"3"}>Verano</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Plataforma"
                            variant="outlined"
                            select
                            value={implementationPlatform}
                            onChange={(e) => {
                                setImplementationPlatform(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, implementationPlatform: "" }))
                            }}
                            error={!!errors.implementationPlatform}
                            helperText={errors.implementationPlatform}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"moodle"}>Moodle</MenuItem>
                            <MenuItem value={"u-cursos"}>U-Cursos</MenuItem>
                            <MenuItem value={"cmmeduformacion"}>Open edX (CMMEdu Formación)</MenuItem>
                            <MenuItem value={"redfid"}>Open edX (RedFID)</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button startIcon={<AddCircleOutlineIcon />} variant='blue' onClick={handleCreateImplementation} disabled={formLoading}>
                            Crear implementación
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CatalogDiplomadoImplementationCreateModal;