import axios from 'axios';

export const postUpdateCourseData = async (data) => {

    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + "/update_course_data", data, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        switch (response.data.status) {
            case 100:
                return { success: true, error: "" };
            case 101:
                return { success: false, error: response.data.message };
            case 102:
                return { success: false, error: response.data.message };
            case 103:
                return { success: false, error: response.data.message };
            case 104:
                return { success: false, error: response.data.message };
            default:
                return { success: false, error: "Error desconocido: " + response.data.message };
        }
    } catch (error) {
        return { success: false, error: "Error desconocido: " + error.message };
    }
}