import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        title: {
            fontSize: {
                xs: "1em",
                md: "1.5em"
            },
            color: '#646464',
            fontFamily: 'Avenir Heavy',
            marginBottom: '20px',
            display: 'block',
            width: '100%',
            textAlign: 'left',
            fontWeight: 'bold'
        },
        "main-title": {
            fontSize: '2em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#646464',
            fontFamily: 'Avenir Heavy',
            marginBottom: '20px',
            display: 'block',
            width: '100%',
            textAlign: 'left',
            fontWeight: 'bold'
        },
        "table-title": {
            fontSize: '1em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#646464',
            fontFamily: 'Avenir Heavy',
            marginLeft: '10px',
            textAlign: 'left',
            fontWeight: 'bold'
        },
        "title-small": {
            fontSize: '1.2em',
            verflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#646464',
            fontFamily: 'Avenir Heavy',
            marginBottom: '20px',
            display: 'block',
            width: '100%',
            textAlign: 'left',
            fontWeight: 'bold'
        },
        "header-nav": {
            padding: "15px 40px", 
            color: "#60696C", 
            textDecoration: "none", 
            fontFamily: "DIN", 
            fontSize: "0.75em",
            cursor: "pointer",
            "&:hover": {
                color: "white",
                backgroundColor: "#60696C",
            }
        },
        "header-nav-s": {
            padding: "15px 40px", 
            color: "white", 
            backgroundColor: "#60696C",
            textDecoration: "none", 
            fontFamily: "DIN", 
            fontSize: "0.75em",
            cursor: "pointer"
        },
        subtitle: {
            fontSize: '1.8em',
            color: '#646464',
            fontFamily: 'DIN Bold',
            marginBottom: '20px',
            display: 'block',
            width: '100%',
            textAlign: 'left'
        },
        "go-back": {
            fontSize: '0.8em',
            color: '#40b4ba',
            fontFamily: 'Avenir Regular',
            cursor: 'pointer',
            display: 'block',
            width: '100%',
            textAlign: 'left'
        },
        a: {
            fontSize: '1em',
            fontFamily: 'Avenir Regular',
            color: '#40b4ba',
            cursor: 'pointer',
            display: 'block',
        },
        back: {
            fontSize: '0.8em',
            fontFamily: 'Avenir Regular',
            color: '#40b4ba',
            cursor: 'pointer',
            display: 'block',
        },
        p: {
            fontFamily: 'Avenir Regular',
            color: '#646464',
            display: 'block',
            textAlign: 'justify',
        },
        "p-error": {
            fontSize: '0.85em',
            fontFamily: 'Avenir Regular',
            color: '#d23831',
            display: 'block',
            textAlign: 'justify'
        },
        "p-small": {
            fontSize: '0.85em',
            fontFamily: 'Avenir Regular',
            color: '#646464',
            display: 'block',
            textAlign: 'justify'
        },
        "caption": {
            wordBreak: "break-all"
        }
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'red' },
                    style: {
                        color: 'white',
                        backgroundColor: '#d23831',
                        '&:hover': {
                            backgroundColor: '#d23831',
                        },
                        '&:disabled': {
                            color: 'white',
                        },
                        fontFamily: 'Avenir Regular',
                    },
                },
                {
                    props: { variant: 'blue' },
                    style: {
                        color: 'white',
                        backgroundColor: '#5BC0EB',
                        '&:hover': {
                            backgroundColor: '#5BC0EB',
                        },
                        '&:disabled': {
                            color: 'white',
                        },
                        fontFamily: 'Avenir Regular',
                    },
                },
                {
                    props: { variant: 'confirm' },
                    style: {
                        "textTransform": "none", 
                        "padding": "10px 20px", 
                        "backgroundColor": "#eb947e", 
                        "color": "white", 
                        "fontWeight": "bold", 
                        "border": "none", 
                        "borderRadius": "5px", 
                        "cursor": "pointer",
                        "&:hover": {
                            backgroundColor: '#eb947e',
                        },
                        fontFamily: 'Avenir Regular',
                    }
                },
                {
                    props: { variant: 'cancel' },
                    style: {
                        "textTransform": "none", 
                        "padding": "10px 20px", 
                        "color": "#646464", 
                        "fontWeight": "bold", 
                        "border": "none", 
                        "borderRadius": "5px", 
                        "cursor": "pointer",
                        "&:hover": {
                            backgroundColor: 'white',
                        },
                        fontFamily: 'Avenir Regular',
                    }
                }
            ]
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: '#d23831',
                }
            }
        },
        MuiIcon: {
            variants: [
                {
                    props: { variant: 'back' },
                    style: {
                        color: '#d23831',
                    },
                }
            ]
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#d23831',
                    "&.Mui-checked": {
                        color: '#d23831',
                    }
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: '0.85em',
                    fontFamily: 'Avenir Regular',
                    color: '#646464',
                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);',
                    backgroundColor: 'white'
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.4em',
                    color: '#646464',
                    fontFamily: 'Avenir Heavy',
                    marginBottom: '20px',
                    display: 'block',
                    width: '100%',
                    textAlign: 'left',
                    fontWeight: 'bold'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontFamily: 'Avenir Regular',
                    color: '#646464',
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '0.85em',
                    fontFamily: 'Avenir Regular',
                    color: '#646464',
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: '0.85em',
                    fontFamily: 'Avenir Regular',
                    color: '#646464',
                    backgroundColor: 'white'
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.85em',
                    fontFamily: 'Avenir Regular',
                    color: '#646464',
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontFamily: 'Avenir Regular',
                    color: '#646464',
                    fontSize: "0.85em",
                    textAlign: "start"
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: 'Avenir Regular',
                    color: '#d23831',
                    cursor: 'pointer',
                    textDecorationColor: '#d23831',
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontFamily: 'Avenir Regular',
                    color: '#646464',
                    fontSize: "0.85em",
                    textAlign: "justify"
                },

            },
            variants: [
                {
                    props: { variant: 'e-learning' },
                    style: {
                        color: 'white',
                        backgroundColor: '#388659',
                        fontFamily: 'Avenir Regular',
                        borderRadius: '10px',
                        fontWeight: 'bold',
                        fontSize: '0.75em',
                        height: '20px',
                        padding: '12px 0',
                    },
                },
                {
                    props: { variant: 'b-learning' },
                    style: {
                        color: 'white',
                        backgroundColor: '#4E4187',
                        fontFamily: 'Avenir Regular',
                        borderRadius: '10px',
                        fontWeight: 'bold',
                        fontSize: '0.75em',
                        height: '20px',
                        padding: '12px 0',
                    },
                },
                {
                    props: { variant: 'eje-tematico' },
                    style: {
                        color: 'white',
                        backgroundColor: '#d23831',
                        fontFamily: 'Avenir Regular',
                        borderRadius: '10px',
                        fontWeight: 'bold',
                        fontSize: '0.75em',
                        height: '20px',
                        padding: '12px 0',
                    },
                },
                {
                    props: { variant: '1' },
                    style: {
                        color: 'white',
                        backgroundColor: '#5BC0EB',
                        fontFamily: 'Avenir Regular',
                        borderRadius: '10px',
                        fontWeight: 'bold',
                        fontSize: '0.75em',
                        height: '20px',
                        padding: '12px 0',
                    },
                },
                {
                    props: { variant: '2' },
                    style: {
                        color: 'white',
                        backgroundColor: '#DB5ABA',
                        fontFamily: 'Avenir Regular',
                        borderRadius: '10px',
                        fontWeight: 'bold',
                        fontSize: '0.75em',
                        height: '20px',
                        padding: '12px 0',
                    },
                },
                {
                    props: { variant: '12' },
                    style: {
                        color: 'white',
                        backgroundColor: '#9BC53D',
                        fontFamily: 'Avenir Regular',
                        borderRadius: '10px',
                        fontWeight: 'bold',
                        fontSize: '0.75em',
                        height: '20px',
                        padding: '12px 0',
                    },
                },
                {
                    props: { variant: '3' },
                    style: {
                        color: 'white',
                        backgroundColor: '#595959',
                        fontFamily: 'Avenir Regular',
                        borderRadius: '10px',
                        fontWeight: 'bold',
                        fontSize: '0.75em',
                        height: '20px',
                        padding: '12px 0',
                    },
                }
            ]
        },
    },
});

export default theme;