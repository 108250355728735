import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Grid, Typography, Button } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from "@mui/icons-material/Close";
import CatalogDiplomadoImplementationCreateModal from "./crud/CatalogDiplomadoImplementationCreateModal";
import CatalogDiplomadoImplementation from "../../../catalog/CatalogDiplomadoImplementation";


const CatalogDiplomadoImplementationsModal = ({
    keycloak,
    open,
    handleClose,
    catalog,
    setCatalog,
    diplomadoIndex,
    moduleIndex,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [thisModule, setThisModule] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);

    const handleOpenCreateModal = () => {
        setShowCreateModal(true);
    }

    const handleCloseCreateModal = () => {
        setShowCreateModal(false);
    }

    useEffect(() => {
        setThisModule(catalog.diplomados[diplomadoIndex].modules[moduleIndex]);
    }, [catalog]);
    
    return (
        thisModule && 
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"lg"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.2em" }}>
                {catalog.diplomados[diplomadoIndex].name}
            </DialogTitle>
            <DialogTitle sx={{ m: 0, pl: 3, pr: 3, pt: 0, pb: 1, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.0em" }}>
                {catalog.diplomados[diplomadoIndex].modules[moduleIndex].module} — {catalog.diplomados[diplomadoIndex].modules[moduleIndex].name}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    {thisModule.implementations.length === 0 ?
                        <Grid item xs={12} mb={"20px"}>
                            <Typography variant={"p"} fontSize={"0.8em"} textAlign={"center"}>No hay implementaciones.</Typography>
                        </Grid>
                        :
                        thisModule.implementations.map((implementation, implementationIndex) => {
                            return (
                                <Grid item xs={12} md={6} key={implementationIndex}>
                                    <CatalogDiplomadoImplementation
                                        keycloak={keycloak}
                                        catalog={catalog}
                                        setCatalog={setCatalog}
                                        diplomadoIndex={diplomadoIndex}
                                        moduleIndex={moduleIndex}
                                        implementationIndex={implementationIndex}
                                        setAlertSuccess={setAlertSuccess}
                                        setAlertError={setAlertError}
                                        alerts={alerts}
                                        setAlerts={setAlerts}
                                    ></CatalogDiplomadoImplementation>
                                </Grid>
                            );
                        })
                    }
                    <Grid item xs={12} textAlign={"center"}>
                        <Button variant='blue' onClick={handleOpenCreateModal} startIcon={<AddCircleOutlineIcon />}>
                            Nueva implementación
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <CatalogDiplomadoImplementationCreateModal
                keycloak={keycloak}
                open={showCreateModal}
                handleClose={handleCloseCreateModal}
                catalog={catalog}
                setCatalog={setCatalog}
                diplomadoIndex={diplomadoIndex}
                moduleIndex={moduleIndex}
                otherImplementations={thisModule.implementations}
                setAlertSuccess={setAlertSuccess}
                setAlertError={setAlertError}
                alerts={alerts}
                setAlerts={setAlerts}
            />
        </Dialog>
    );

}

export default CatalogDiplomadoImplementationsModal;