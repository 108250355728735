import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CatalogCourseImplementationCreateModal from './crud/CatalogCourseImplementationCreateModal';
import CatalogCourseImplementation from '../../../catalog/CatalogCourseImplementation';


const CatalogCourseModal = ({
    keycloak,
    open,
    handleClose,
    index,
    catalog,
    setCatalog,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [course, setCourse] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);

    const handleOpenCreateModal = () => {
        setShowCreateModal(true);
    }

    const handleCloseCreateModal = () => {
        setShowCreateModal(false);
    }

    useEffect(() => {
        setCourse(catalog.courses[index]);
    }, [catalog]);
    
    return (
        course && 
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"lg"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.2em" }}>
                {course.shortname} — {course.name}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography fontSize={"0.8em"} mb={"10px"} variant="p"><b><u>Descripción:</u></b> {course.description}</Typography>
                    </Grid>
                    {course.implementations.length === 0 ?
                        <Grid item xs={12} mb={"20px"}>
                            <Typography variant={"p"} fontSize={"0.8em"} textAlign={"center"}>No hay implementaciones.</Typography>
                        </Grid>
                        :
                        course.implementations.map((implementation, implementationIndex) => {
                            return (
                                <Grid item xs={12} md={4} key={implementationIndex}>
                                    <CatalogCourseImplementation
                                        keycloak={keycloak}
                                        courseIndex={index}
                                        implementationIndex={implementationIndex}
                                        catalog={catalog}
                                        setCatalog={setCatalog}
                                        setAlertSuccess={setAlertSuccess}
                                        setAlertError={setAlertError}
                                        alerts={alerts}
                                        setAlerts={setAlerts}
                                    />
                                </Grid>
                            );
                        })}
                    <Grid item xs={12} textAlign={"center"}>
                        <Button variant='blue' onClick={handleOpenCreateModal} startIcon={<AddCircleOutlineIcon />}>
                            Nueva implementación
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <CatalogCourseImplementationCreateModal
                keycloak={keycloak}
                open={showCreateModal}
                handleClose={handleCloseCreateModal}
                courseIndex={index}
                catalog={catalog}
                setCatalog={setCatalog}
                otherImplementations={course.implementations}
                setAlertSuccess={setAlertSuccess}
                setAlertError={setAlertError}
                alerts={alerts}
                setAlerts={setAlerts}
            />
        </Dialog>
    );
}

export default CatalogCourseModal;