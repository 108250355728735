import React, { useState, useEffect } from "react";
import { TextField, Dialog, DialogContent, DialogTitle, IconButton, Grid, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { putCourseSection } from "../../../../../../requests/catalog/putCourseSection";


const CatalogCourseSectionEditModal = ({
    keycloak,
    open,
    handleClose,
    courseIndex,
    implementationIndex,
    sectionIndex,
    catalog,
    setCatalog,
    otherSections,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [sectionCourseKey, setSectionCourseKey] = useState("");
    const [sectionLocation, setSectionLocation] = useState("");
    const [sectionSection, setSectionSection] = useState("");
    const [errors, setErrors] = useState({});
    const [formLoading, setFormLoading] = useState(false);

    const validateForm = () => {
        let errors = {};
        if (sectionCourseKey === "") {
            errors.sectionCourseKey = "Por favor ingrese un course key.";
        }
        if (sectionLocation === "") {
            errors.sectionLocation = "Por favor ingrese una ubicación.";
        } else if (sectionLocation.length !== 3) {
            errors.sectionLocation = "Por favor ingrese una ubicación de 3 caracteres.";
        } else if (sectionLocation !== sectionLocation.toUpperCase()) {
            errors.sectionLocation = "Por favor ingrese una ubicación en mayúsculas.";
        }
        if (sectionSection === "") {
            errors.sectionSection = "Por favor ingrese un número de sección.";
        } else if (sectionSection.length !== 2) {
            errors.sectionSection = "Por favor ingrese un número de sección de 2 caracteres.";
        } else if (isNaN(sectionSection)) {
            errors.sectionSection = "Por favor ingrese un número de sección válido.";
        }
        if (otherSections.some(section => section.course_key === sectionCourseKey)) {
            errors.sectionCourseKey = "Ya existe una sección con este course key.";
        }
        if (otherSections.some(section => section.location === sectionLocation && section.section === sectionSection)) {
            errors.sectionLocation = "Ya existe una sección con estos datos.";
            errors.sectionSection = "Ya existe una sección con estos datos.";
        }
        return errors;
    }

    const handleEditSection = async () => {
        setErrors({});
        let validateErrors = validateForm();
        if (Object.keys(validateErrors).length !== 0) {
            setErrors(validateErrors);
            return;
        }
        setFormLoading(true);
        let editData = {
            "id": catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex]._id,
            "course_implementation_id": catalog.courses[courseIndex].implementations[implementationIndex]._id,
            "course_key": sectionCourseKey,
            "location": sectionLocation,
            "section": sectionSection,
            "seguimiento_enabled": catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].seguimiento_enabled
        }
        let response = await putCourseSection(keycloak.token, editData);
        if (response.catalog == null) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al editar sección", "text": response.error } });
        } else {
            setCatalog(response.catalog);
            handleClose();
            setSectionCourseKey("");
            setSectionLocation("");
            setSectionSection("");
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Sección editada!`, "text": `La sección de la implementación ${catalog.courses[courseIndex].implementations[implementationIndex].year}-${catalog.courses[courseIndex].implementations[implementationIndex].term} (${catalog.courses[courseIndex].implementations[implementationIndex].type}) del curso ${catalog.courses[courseIndex].shortname} se ha editado correctamente.` } });
        }
        setFormLoading(false);
    }

    useEffect(() => {
        if (catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex] === undefined) {
            handleClose();
            return;
        }
        setSectionCourseKey(catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].course_key);
        setSectionLocation(catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].location);
        setSectionSection(catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].section);
    }, [catalog]);

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.2em" }}>
                Editar sección
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Course key"
                            variant="outlined"
                            value={sectionCourseKey}
                            onChange={(e) => {
                                setSectionCourseKey(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, sectionCourseKey: "" }))
                            }}
                            error={!!errors.sectionCourseKey}
                            helperText={errors.sectionCourseKey}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Ubicación"
                            variant="outlined"
                            value={sectionLocation}
                            onChange={(e) => {
                                setSectionLocation(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, sectionLocation: "" }))
                            }}
                            error={!!errors.sectionLocation}
                            helperText={errors.sectionLocation}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Número de sección"
                            variant="outlined"
                            value={sectionSection}
                            onChange={(e) => {
                                setSectionSection(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, sectionSection: "" }))
                            }}
                            error={!!errors.sectionSection}
                            helperText={errors.sectionSection}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button startIcon={<EditIcon />} variant='blue' onClick={handleEditSection} disabled={formLoading}>
                            Editar sección
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )

}

export default CatalogCourseSectionEditModal;